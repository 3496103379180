import React from 'react';
import { Provider } from 'react-redux';
import ProgressBar from './ProgressBar';

const ConnectedProgressBar = props => (
  <Provider store={ReactOnRails.getStore('appStore')}>
    <ProgressBar />
  </Provider>
);

export default ConnectedProgressBar;
