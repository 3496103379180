export const UPDATE_PURCHASE_SUMMARY_MESSAGE =
  'UPDATE_PURCHASE_SUMMARY_MESSAGE';

const initialState = 'Thank you for your purchase!';

const purchaseSummaryMessage = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PURCHASE_SUMMARY_MESSAGE:
      return action.message;
    default:
      return state;
  }
};

export default purchaseSummaryMessage;
