import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropzone from './Dropzone';
import PngFile from 'images/icons/png_file.svg';
import JpgFile from 'images/icons/jpg_file.svg';
import ImageFile from 'images/icons/image_file.svg';
import Clear from 'images/icons/clear.svg';

class Uploader extends Component {
  state = {
    files: [],
    fakeUploading: false
  };

  fileInputRef = React.createRef();

  handleClearFile = () => {
    this.fileInputRef.current.value = '';
    this.setState({ files: [] });
  };

  onFilesAdded = files => {
    this.setState({ files: files });
    files.forEach(() => {
      let load = 2000;
      this.setState({ fakeUploading: true });
      window.setTimeout(() => {
        this.setState({ fakeUploading: false });
      }, load);
    });
  };

  renderFileType = file => {
    const fileType = file.type.split('/')[1];
    if (fileType === 'png') {
      return <PngFile className="stores-Upload-icon" />;
    } else if (fileType === 'jpeg') {
      return <JpgFile className="stores-Upload-icon" />;
    } else {
      return <ImageFile className="stores-Upload-icon" />;
    }
  };

  render() {
    const { files, fakeUploading } = this.state;
    return (
      <React.Fragment>
        <div className="stores-Upload">
          <div className="stores-Upload-content">
            <Dropzone
              id={this.props.id}
              name={this.props.name}
              ref={this.fileInputRef}
              onFilesAdded={this.onFilesAdded}
              errors={this.props.errors}
            />
            <div className="stores-Upload-files">
              {files.map(file => {
                return (
                  <div key={file.name} className="stores-Upload-row">
                    {this.renderFileType(file)}
                    <div className="stores-Upload-row-content">
                      <div className="stores-Upload-fileName">
                        <strong>{file.name}</strong>
                        {Math.trunc(file.size / 1000)}KB
                      </div>
                      <div
                        className={`stores-Upload-progress ${
                          fakeUploading ? 'stores-Upload-progress--active' : ''
                        }`}
                      />
                    </div>
                    <Clear
                      className="stores-Upload-clear"
                      onClick={this.handleClearFile}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Uploader.propTypes = {
  errors: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string
};

export default Uploader;
