import * as React from 'react';
import { Button } from 'components/elements';

const noop = () => {};

// options need to be json {value, display}

const SplitButton = ({ options, onClick, disabled }) => {
  const [selected, setSelected] = React.useState(options[0]);

  const handleClick = () => {
    onClick(selected.value);
  };

  const onSelectChange = event => {
    const value = event.target.value;
    const selectionOption = options.find(option => option.value == value);
    setSelected(selectionOption);
  };

  return (
    <React.Fragment>
      <div className="splitButton">
        <Button
          onClick={handleClick}
          disabled={disabled}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        >
          {selected.display}
        </Button>
        <select disabled={disabled} onChange={onSelectChange}>
          {options.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.display}
              </option>
            );
          })}
        </select>
      </div>
    </React.Fragment>
  );
};

SplitButton.defaultProps = {
  options: [],
  onClick: noop
};

export default SplitButton;
