import React from 'react';
import { Provider } from 'react-redux';

import BundleContainer from './BundleContainer';

const ConnectedBundleContainer = props => (
  <Provider store={ReactOnRails.getStore('appStore')}>
    <BundleContainer />
  </Provider>
);

export default ConnectedBundleContainer;
