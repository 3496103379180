import React from 'react';
import PropTypes from 'prop-types';
import DropDown, { DropDownItem } from '../elements/DropDown';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modal';
import { metrics } from 'utils/metrics';

const PurchaseConfirmationMenu = ({
  referenceId,
  cancelable,
  cancelUrl,
  shippingToLocation,
  unfulfilled,
  isInventoryStore,
  dispatch
}) => {
  function onChangeEmail() {
    metrics.pageViewTag('/os/orderstatus/statuspage/update/changeemail');
    dispatch(openModal('PurchaseUpdateEmail', {}));
  }
  function onChangeShippingAddress() {
    metrics.pageViewTag('/os/orderstatus/statuspage/update/changeaddress');
    dispatch(openModal('PurchaseUpdateShippingAddress', {}));
  }
  function onCancel() {
    metrics.pageViewTag('/os/orderstatus/statuspage/update/cancelorder');
    window.location.href = cancelUrl;
  }
  return (
    <div className="stores-PurchaseConfirmation-menu">
      <h1>Order {referenceId}</h1>
      {!isInventoryStore && <DropDown label="Update Your Order">
        <DropDownItem onClick={onChangeEmail}>
          Change Your Email Address
        </DropDownItem>
        {!shippingToLocation && unfulfilled && (
          <DropDownItem onClick={onChangeShippingAddress}>
            Change Your Shipping Address
          </DropDownItem>
        )}
        {cancelable && (
          <DropDownItem onClick={onCancel}>
            <a
              data-confirm="Are you sure? This order will be canceled immediately."
              data-disable="true"
              rel="nofollow"
              data-method="patch"
              href={cancelUrl}
            >
              Cancel Your Order
            </a>
          </DropDownItem>
        )}
      </DropDown>}
    </div>
  );
};

PurchaseConfirmationMenu.propTypes = {
  referenceId: PropTypes.string.isRequired,
  cancelable: PropTypes.bool.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  unfulfilled: PropTypes.bool.isRequired,
  isInventoryStore: PropTypes.bool
};

export default connect()(PurchaseConfirmationMenu);
