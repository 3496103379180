import * as actions from 'reducers/purchase';

export function changePurchase(purchase) {
  return {
    type: actions.CHANGE_PURCHASE,
    data: {
      purchase
    }
  };
}
