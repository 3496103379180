import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ProductBadge } from 'components/elements';
import Images from 'components/ProductImages';
import Form from 'components/ProductForm';
import ProductDetailsShareButtons from 'components/ProductDetailsShareButtons';

const ProductDetails = ({
  name,
  badgeText,
  description,
  deliveryEstimate,
  elevateShipping,
  fulfillmentDate,
  originalPrice,
  price,
  productId,
  undecorated,
  videos
}) => (
  <React.Fragment>
    <div className="stores-ProductDetails-details">
      {badgeText && (
        <div className="stores-ProductDetails-productBadge">
          <ProductBadge text={badgeText} />
        </div>
      )}
      <h1>{name}</h1>
      {undecorated && elevateShipping && (
        <div className="stores-ProductDetails-shipmentInfo">
          <span className="stores-ProductDetails-details--fulfillmentDate">
            Estimated to ship on {fulfillmentDate}
          </span>
          <span className="stores-ProductDetails-details--deliveryEstimate">
            , delivery within {deliveryEstimate}
          </span>
        </div>
      )}
      <div className="stores-ProductDetails-details--price">
        {originalPrice && (
          <span>
            <strike className="stores-Util-strikethrough">
              Originally {originalPrice}
            </strike>{' '}
            Now{' '}
          </span>
        )}
        {price}
      </div>
      <ProductDetailsShareButtons productId={productId} />
      <div className="sb-Util-visible--sm">
        <Images navigationClass="stores-ProductDetails-mobileCarousel" />
      </div>
      <div
        className="stores-ProductDetails-description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      <Form />
    </div>
    <div className="stores-ProductDetails-images">
      <div className="sb-Util-hidden--sm">
        <Images navigationClass="stores-ProductDetails-desktopCarousel" />
      </div>
      {videos && videos[0] && (
        <div className="stores-ProductDetails-staticVideo">
          <iframe
            src={videos[0]}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </div>
  </React.Fragment>
);

ProductDetails.propTypes = {
  badgeText: PropTypes.string,
  description: PropTypes.string.isRequired,
  deliveryEstimate: PropTypes.string.isRequired,
  elevateShipping: PropTypes.bool.isRequired,
  fulfillmentDate: PropTypes.string.isRequired,
  limited: PropTypes.bool,
  name: PropTypes.string.isRequired,
  originalPrice: PropTypes.string,
  price: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  undecorated: PropTypes.bool,
  videos: PropTypes.arrayOf(PropTypes.string)
};

function mapStateToProps({ product }) {
  return {
    badgeText: product.badgeText,
    description: product.description,
    deliveryEstimate: product.deliveryEstimate,
    elevateShipping: product.elevateShipping,
    fulfillmentDate: product.fulfillmentDate,
    limited: product.limited,
    name: product.name,
    originalPrice: product.originalPrice,
    price: product.price,
    productId: product.id,
    undecorated: product.undecorated,
    videos: product.videos
  };
}

export default connect(mapStateToProps)(ProductDetails);
