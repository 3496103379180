import React, { Component, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductAdded from './ProductAdded';
import ConfirmAddress from './ConfirmAddress';

import { closeModal } from '../../actions/modal';
import { times } from 'constants/StringConstants';
import PurchaseUpdateShippingAddress from './PurchaseUpdateShippingAddress';
import PurchaseUpdateEmail from './PurchaseUpdateEmail';
import {isInventory} from '../../selectors/storePresentationConfig'

const Modal = ({ name, config, dispatch, isInventoryStore }) => {
  if (!name) return null;

  const contentComponents = {
    ProductAdded,
    ConfirmAddress,
    PurchaseUpdateEmail,
    PurchaseUpdateShippingAddress
  };

  const ModalContent = contentComponents[name];

  const close = () => {
    dispatch(closeModal());

    if (config.onClose) {
      config.onClose();
    }
  };

  const handleBackdropClick = () => {
    !config.static && close();
  };

  if (!config.static) {
    const handleEscKey = useCallback(event => {
      event.key === 'Escape' && close();
    });

    useEffect(() => {
      document.addEventListener('keydown', handleEscKey, false);

      return () => {
        document.removeEventListener('keydown', handleEscKey, false);
      };
    });
  }

  const modalProps = {
    ...(config.props || {}),
    isInventoryStore,
    close
  };

  return (
    <div className="stores-Modal">
      <div className="stores-Modal-backdrop" onClick={handleBackdropClick} />

      <div
        className="stores-Modal-dialog"
        style={{ maxWidth: config.maxWidth }}
      >
        {!config.static && (
          <button className="stores-Modal-closeButton" onClick={close}>
            {times}
          </button>
        )}

        <ModalContent {...modalProps} />
      </div>
    </div>
  );
};

Modal.propTypes = {
  dispatch: PropTypes.func,
  name: PropTypes.string,
  config: PropTypes.shape({
    maxWidth: PropTypes.number,
    static: PropTypes.bool,
    props: PropTypes.object,
    onClose: PropTypes.func
  })
};

const mapStateToProps = state => ({
  name: state.modal.name,
  config: state.modal.config,
  isInventoryStore: isInventory(state)
});

export default connect(mapStateToProps)(Modal);
