import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { getPaymentSkippable } from 'selectors/purchase';

export const SkipPayment = ({ skippable }) => {
  if (!skippable) return null;

  return (
    <div className="sb-Form-group">
      <label className="sb-Form-radio">
        <Field name="payment.skipped" component="input" type="checkbox" />
        <span className="sb-Form-indicator" />
        Skip Payment
      </label>
    </div>
  );
};

const mapStateToProps = state => ({ skippable: getPaymentSkippable(state) });

SkipPayment.propTypes = {
  skippable: PropTypes.bool
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'purchase',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnBlur: false
  })(SkipPayment)
);
