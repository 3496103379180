import * as actions from 'reducers/payment';

export function updateBraintree(braintree) {
  return {
    type: actions.UPDATE_BRAINTREE,
    data: { braintree }
  };
}

export function updateToken(token) {
  return {
    type: actions.UPDATE_TOKEN,
    data: { token }
  };
}
