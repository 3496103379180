import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ProductColorSwatch } from '@customink/react_components';
import { ProductPropTypes } from 'utils/ReusablePropTypes';
import storeId from 'utils/storeId';
import Routes from 'lib/routes';
import { ProductBadge } from 'components/elements';

const ProductCard = ({ product }) => {
  const [image, updateImage] = useState(product.imageUrl);
  const [variant, updateVariant] = useState(null);
  const [swatchSelected, updateSwatchSelected] = useState(false);

  const changeImage = (variantImage, e) => {
    if (swatchSelected || variantImage === image) return;
    updateImage(variantImage);
  };

  const focused = variant => {
    updateSwatchSelected(true);
    updateImage(variant.images[0].url);
    updateVariant(variant);
  };

  return (
    <div className="stores-productCard">
      <a
        href={Routes.store_product_path(storeId(), product.id, {
          variantId: variant ? variant.id : null
        })}
      >
        <div className="stores-productCard-image">
          <img src={image} />
          <ProductBadge hidden={!product.badgeText} text={product.badgeText} />
        </div>
      </a>
      <div className="stores-productCard-details">
        <div className="stores-ColorSwatches">
          {product.variants.map(variant => (
            <ProductColorSwatch
              key={variant.id}
              className="stores-ColorSwatches-swatch"
              onMouseEnter={e => changeImage(variant.images[0].url, e)}
              onMouseLeave={e => changeImage(product.imageUrl, e)}
              onFocus={e => focused(variant)}
              rgb={variant.rgb}
              trimRgb={variant.trimRgb}
            />
          ))}
        </div>
        <a
          href={Routes.store_product_path(storeId(), product.id, {
            variantId: variant ? variant.id : null
          })}
        >
          <h5 className={`stores-productCard-name ${product.detailsProvider ? product.detailsProvider : ''}`}>{product.name}</h5>
          <div className="stores-productCard-subDetails">
            <div className="stores-productCard-price">
              {product.originalPrice && (
                <span>
                  <strike className="stores-Util-strikethrough">
                    Originally {product.originalPrice}
                  </strike>{' '}
                  Now{' '}
                </span>
              )}
              {product.price}
            </div>
            {product.limited && (
              <div className="stores-productCard-timeLeftToOrder">
                {product.timeLeftToOrder}
              </div>
            )}
          </div>
        </a>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape(ProductPropTypes)
};

export default ProductCard;
