import { SubmissionError } from 'redux-form';
import axios from 'axios';

import { changePurchase } from 'actions/purchase';
import Routes from 'lib/routes';
import { metrics } from 'utils/metrics';
import storeId from 'utils/storeId';

export default async function(dispatch, values) {
  const csrfToken = document.querySelector('[name="csrf-token"]').content;
  const config = {
    headers: {
      'x-requested-with': 'XMLHttpRequest'
    }
  };

  try {
    const { data } = await axios.patch(
      Routes.store_purchase_path(storeId(), values.id),
      values,
      config
    );

    return data;
  } catch (error) {
    console.error(error.toString());
    throw error;
  }
}
