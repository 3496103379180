import React from 'react';

import { RecommendedProductsPropTypes } from 'utils/ReusablePropTypes';

const RecommendedProducts = ({ products }) => {
  const renderProduct = product => {
    return (
      <div key={product.key} className="stores-Recommendations-item">
        <a href={product.href}>
          <img src={product.imageUrl} />
          <p>{product.name}</p>
        </a>
      </div>
    );
  };

  return (
    <>
      <h4>You might also like</h4>
      <div className="stores-Recommendations-items">
        {products.map(product => renderProduct(product))}
      </div>
    </>
  );
};

RecommendedProducts.propTypes = {
  products: RecommendedProductsPropTypes.isRequired
};

export default RecommendedProducts;
