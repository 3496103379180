import { createSelector } from 'reselect';

const shipmentsSelector = state => state.purchaseConfirmation.shipments;
export const filterSelector = state => state.filters[0];

export const getFilteredShipments = createSelector(
  shipmentsSelector,
  filterSelector,
  (shipments, filter) => {
    if (!filter) return shipments;

    return shipments.filter(
      shipment => shipment.status.statusName == filter.statusName
    );
  }
);
