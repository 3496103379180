import React from 'react';
import { Provider } from 'react-redux';
import ProductsContainer from './ProductsContainer';

const ConnectedProductsContainer = props => (
  <Provider store={ReactOnRails.getStore('appStore')}>
    <ProductsContainer />
  </Provider>
);

export default ConnectedProductsContainer;
