import React from 'react';
import PropTypes from 'prop-types';

const SizeBox = ({ onFocus, size, disabled, selected, flex }) => {
  const selectedClass = `${selected ? ' stores-SizeBox--selected' : ''}`;
  const disabledClass = `${disabled ? ' stores-SizeBox--disabled' : ''}`;
  const flexClass = `${flex ? ' stores-SizeBox--flex' : ''}`;

  return (
    <div
      disabled={disabled}
      onFocus={onFocus}
      onClick={onFocus}
      tabIndex="0"
      className={`stores-SizeBox${selectedClass}${disabledClass}${flexClass}`}
    >
      {size}
    </div>
  );
};

SizeBox.propTypes = {
  onFocus: PropTypes.func
};

export default SizeBox;
