import React, { Component } from 'react';
import PropTypes from 'prop-types';

export function FormGroup({
  input,
  label,
  placeholder,
  className = '',
  type,
  options = [],
  optionValue,
  optionLabel,
  meta: { touched, error },
  ...fieldProps
}) {
  const hasError = touched && error;
  const errorClass = hasError ? 'is-fieldWithErrors' : '';
  const formErrorClass = hasError ? 'sb-Form-fieldErrors' : '';

  fieldProps = {
    className: errorClass,
    ...input,
    ...fieldProps
  }

  const renderSelect = () => (
    <select {...fieldProps}>
      {placeholder && (
        <option value={""} disabled>
          { placeholder }
        </option>
      )}

      {options.map(option => {
        const value = optionValue === undefined ? option : option[optionValue]
        const label = optionLabel === undefined ? option : option[optionLabel]

        return (
          <option key={value} value={value}>
            { label }
          </option>
        )
      })}
    </select>
  )

  const renderInput = () => (
    <input type={type} placeholder={placeholder || label} {...fieldProps} />
  )

  return (
    <div className={`sb-Form-group ${errorClass} ${className}`}>
      <label className={errorClass}>
        {label}
      </label>

      <div>
        {type === 'select' ? renderSelect() : renderInput()}
        {hasError && <div className={formErrorClass}>{error}</div>}
      </div>
    </div>
  );
}

FormGroup.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.string
};
