// PENDING: [HY-1754] Shipping addresses and other props in this component are inconsistent with casing (camel vs snake)
export function translateAddressAttributesToSnakeCase(address) {
  return {
    full_name: address.full_name,
    address_1: address.address1,
    address_2: address.address2,
    city: address.city,
    postal_code: address.postalCode,
    state: address.state,
    country: address.country
  }
}
