import React from 'react';

export default ({ input, label, options, className, flex, $component }) => {
  return (
    <div className="sb-Form-group">
      <h2>{label}</h2>
      <div className={className}>
        {options.map(option => (
          <$component
            key={option.value}
            className={option.className}
            selected={option.value === input.value}
            value={input.value}
            disabled={option.disabled}
            flex={flex}
            onFocus={e => {
              !option.disabled && input.onChange(option.value);
            }}
            {...option.data}
          />
        ))}
      </div>
    </div>
  );
};
