export const UPDATE_SECTION = 'UPDATE_SECTION';

const initialState = {
  section: 0
};

const purchaseForm = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SECTION:
      return { ...state, section: action.section };
    default:
      return state;
  }
};

export default purchaseForm;
