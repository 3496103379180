import * as actions from 'reducers/itemStatuses';

export function updateStatuses(results) {
  return {
    type: actions.UPDATE_STATUSES,
    data: {
      columns: results.columns,
      data: results.data
    }
  };
}
