import * as actions from 'reducers/modal';

export function openModal(name, config = {}) {
  return {
    type: actions.OPEN_MODAL,
    data: {
      name,
      config
    }
  };
}

export function closeModal(name) {
  return {
    type: actions.CLOSE_MODAL
  };
}
