import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import dropin from 'braintree-web-drop-in';

import { updateBraintree } from 'actions/payment';

const PaymentEntry = props => {
  if (props.disabled)
    return (
      <p>
        <strong>No Payment Required</strong>
      </p>
    );

  const container = '#purchase-payment-entry';
  useEffect(() => {
    if (props.authorization) {
      dropin
        .create({
          authorization: props.authorization,
          container,
          paypal: {
            flow: 'vault'
          },
          applePay: {
            displayName: props.poweredByName,
            paymentRequest: {
              total: {
                label: 'Total Price',
                amount: props.total
              },
              currencyCode: props.currency
            }
          }
        })
        .then(braintree => {
          props.updateBraintree(braintree);
          braintree.on('paymentMethodRequestable', () =>
            props.setSubmittable(true)
          );
          braintree.on('noPaymentMethodRequestable', () =>
            props.setSubmittable(false)
          );
        });
    }
  });

  return (
    <div
      key={props.authorization}
      className="stores-PaymentEntry"
      id="purchase-payment-entry"
    />
  );
};

PaymentEntry.propTypes = {
  authorization: PropTypes.string,
  total: PropTypes.string,
  setSubmittable: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const mapStateToProps = state => ({
  authorization: state.payment.token,
  total: state.purchase.authorizationTotal,
  currency: state.purchase.authorizationCurrency,
  poweredByName: state.store.poweredByName
});

const mapDispatchToProps = dispatch => ({
  updateBraintree: braintree => dispatch(updateBraintree(braintree))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentEntry);
