import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RecommendedProductsPropTypes } from 'utils/ReusablePropTypes';

import RecommendedProducts from 'components/RecommendedProducts';

import { getNormalizedRecommendedProducts } from 'selectors/recommendedProducts';

const RecommendedProductsContainer = ({ context, products }) => {
  let contextClass = '';
  if (!!context) contextClass = `stores-Recommendations-wrapper--${context}`;

  return (
    products.length > 0 && (
      <div className={`stores-Recommendations-wrapper ${contextClass}`}>
        <div className="stores-Recommendations-content sb-Wrapper">
          <RecommendedProducts products={products} />
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    products: ownProps.products || getNormalizedRecommendedProducts(state)
  };
};

RecommendedProductsContainer.propTypes = {
  context: PropTypes.string,
  products: RecommendedProductsPropTypes
};

RecommendedProductsContainer.defaultProps = {
  products: []
};

export default connect(mapStateToProps)(RecommendedProductsContainer);
