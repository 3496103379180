import React from 'react';
import PropTypes from 'prop-types';

import { ImagePropTypes } from 'utils/ReusablePropTypes';
import Form from 'components/BundleForm';
import Images from 'components/BundleImages';

const BundleDetailsSmall = ({
  name,
  description,
  deliveryMessageOverride,
  fulfillmentDate,
  price,
  images
}) => {
  return (
    <>
      <div className="stores-ProductDetails-details">
        <h1>{name}</h1>
        <div className="stores-BundleDetails-deliveryMessage">
          Goes to print on {fulfillmentDate}. {deliveryMessageOverride}
        </div>
        <div className="stores-ProductDetails-details--price">{price}</div>
        <Images images={images} navigationClass="stores-BundleDetails-mobileCarousel" />
        <div
          className="stores-ProductDetails-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        <Form />
      </div>
    </>
  );
};

BundleDetailsSmall.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  deliveryMessageOverride: PropTypes.string.isRequired,
  fulfillmentDate: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)).isRequired
};

export default BundleDetailsSmall;
