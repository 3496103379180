import React from 'react';

const Button = props => {
  const buttonType = props.type || 'primaryBlack';

  return (
    <button
      onClick={props.onClick}
      type={props.htmlType || 'button'}
      className={`sb-Btn sb-Btn--responsive sb-Btn--${buttonType} ${props.className}`}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
