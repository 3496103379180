import React from 'react';
import PropTypes from 'prop-types';

const SizeChart = ({ sizeChartHTML, isHidden, handleToggleSizeChart }) => {
  return (
    <div>
      <a
        className="stores-productDetails-sizeChart--toggle"
        onClick={handleToggleSizeChart}
      >
        Sizing Guide
      </a>
      {!isHidden && (
        <div
          className="stores-productDetails-sizeChart--chart"
          dangerouslySetInnerHTML={{ __html: sizeChartHTML }}
        />
      )}
    </div>
  );
};

SizeChart.propTypes = {
  sizeChartHTML: PropTypes.string.isRequired
};

export default SizeChart;
