import axios from 'axios';

import { addCartItem } from 'actions/cart';
import Routes from 'lib/routes';
import { metrics } from 'utils/metrics';
import storeId from 'utils/storeId';

export default async function(dispatcher, values) {
  const request = { items: values };

  try {
    const response = await axios.post(
      Routes.buy_now_store_cart_path(storeId()),
      request
    );

    metrics.pageViewTag(`/os/${storeId()}/buynow`);

    const { cart } = response.data;

    dispatcher(addCartItem(cart));
    window.location.pathname = Routes.store_new_purchase_path(storeId());
  } catch (error) {
    // TODO: Handle error messaging
    metrics.pageViewTag(`/os/${storeId()}/buynow/error`);
    console.error(error.toString());
  }
}
