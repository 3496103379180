import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ProductBadge } from 'components/elements';
import Images from 'components/ProductImages';
import Form from 'components/DonationProductForm';
import ProductDetailsShareButtons from 'components/ProductDetailsShareButtons';

const DonationProductDetails = ({
  name,
  badgeText,
  description,
  productId
}) => (
  <React.Fragment>
    <div className="stores-ProductDetails-details">
      {badgeText && (
        <div className="stores-ProductDetails-productBadge">
          <ProductBadge text={badgeText} />
        </div>
      )}
      <h1>{name}</h1>
      <ProductDetailsShareButtons productId={productId} />
      <div className="sb-Util-visible--sm">
        <Images navigationClass="stores-ProductDetails-mobileCarousel" />
      </div>
      <div
        className="stores-ProductDetails-description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      <Form />
    </div>
    <div className="stores-ProductDetails-images">
      <div className="sb-Util-hidden--sm">
        <Images navigationClass="stores-ProductDetails-desktopCarousel" />
      </div>
    </div>
  </React.Fragment>
);

DonationProductDetails.propTypes = {
  name: PropTypes.string.isRequired,
  badgeText: PropTypes.string,
  description: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired
};

function mapStateToProps({ product }) {
  return {
    name: product.name,
    badgeText: product.badgeText,
    description: product.description,
    productId: product.id
  };
}

export default connect(mapStateToProps)(DonationProductDetails);
