import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import ItemStatusTable from 'components/ItemStatusTable';

const ItemStatusesSearchResults = ({
  itemStatuses,
  lastSearchQuery,
  timeframe
}) => {
  const itemStatusesCount = itemStatuses.data.length;

  if (lastSearchQuery) {
    return (
      <div className="stores-ItemStatusesSearchResults">
        <div className="sb-Wrapper">
          <p className="stores-ItemStatusesSearchResults-info">
            {pluralize('result', itemStatusesCount, true)} found for{' '}
            <strong>{lastSearchQuery}</strong> over the last {timeframe} days
          </p>
          <ItemStatusTable itemStatuses={itemStatuses} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

ItemStatusesSearchResults.propTypes = {
  itemStatuses: PropTypes.object,
  lastSearchQuery: PropTypes.string,
  timeframe: PropTypes.number
};

export default ItemStatusesSearchResults;
