import React, { Component } from 'react';
import { Provider } from 'react-redux';

import PurchaseForm from 'components/Purchase';
import Summary from 'components/Purchase/Summary';

class ConnectedPurchasePage extends Component {
  render() {
    return (
      <Provider store={ReactOnRails.getStore('appStore')}>
        <section className="stores-Purchases">
          <h2 className="stores-PurchaseHeader">Checkout</h2>
          <PurchaseForm />
          <div className="stores-PurchaseSummary sb-Util-hidden--sm">
            <Summary />
          </div>
        </section>
      </Provider>
    );
  }
}

export default ConnectedPurchasePage;
