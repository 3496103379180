import axios from 'axios';

import { removeCartItem } from 'actions/cart';
import storeId from 'utils/storeId';
import Routes from 'lib/routes';

export default async function(dispatcher, product = {}) {
  try {
    const response = await axios.delete(Routes.store_cart_path(storeId()), {
      data: product
    });
    const { cart } = response.data;
    dispatcher(removeCartItem(cart));
  } catch (error) {
    // TODO: Handle error messaging
    console.error(error.toString());
  }
}
