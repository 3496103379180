import React from 'react';
import PropTypes from 'prop-types';

import { metrics } from 'utils/metrics';

import { default as OrderSummary } from 'components/Purchase/PurchaseConfirmationPageOrderSummary.js';

const PurchaseConfirmationVitals = ({
  placedOn,
  referenceId,
  className,
  cancelable,
  cancelUrl
}) => {
  function handlePrint() {
    metrics.pageViewTag('/os/orderstatus/statuspage/printinvoice');
    window.print();
  }
  return (
    <div className={`stores-PurchaseConfirmation-vitals ${className}`}>
      <div className="stores-PurchaseConfirmation-vitalsDateAndNumber">
        <div className="stores-PurchaseConfirmation-vitalsDate">
          <h3>Order Placed</h3>
          {placedOn}
        </div>
        <div className="stores-PurchaseConfirmation-vitalsNumber">
          <h3>Order Number</h3>
          {referenceId}
        </div>
      </div>
      <OrderSummary />
      <div className="stores-PurchaseConfirmation-vitalsPrintInvoice">
        <a href="#" onClick={handlePrint}>
          <i className="stores-PurchaseConfirmation-vitalsPrintInvoice-icon" />{' '}
          Print Your Invoice
        </a>
      </div>
    </div>
  );
};

PurchaseConfirmationVitals.propTypes = {
  placedOn: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired
};

export default PurchaseConfirmationVitals;
