export const ADD_SINGLE_FILTER = 'ADD_SINGLE_FILTER';
export const REMOVE_SINGLE_FILTER = 'REMOVE_SINGLE_FILTER';

const initialState = [];

const filters = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SINGLE_FILTER:
      return [action.data.filter];
    case REMOVE_SINGLE_FILTER:
      return [];
    default:
      return state;
  }
};

export default filters;
