import React from 'react';
import PropTypes from 'prop-types';
import { CartItemsPropTypes } from 'utils/ReusablePropTypes';
import { connect } from 'react-redux';

import CartItem from 'components/CartItem';
import RecommendedProductsContainer from 'components/RecommendedProductsContainer';
import storeId from 'utils/storeId';
import Routes from 'lib/routes';

const ProductAdded = ({ cart, isInventoryStore  }) => {
  const { quantity, total } = cart;

  return (
    <>
      <div className="stores-Modal-header">
        <h4>Item added to your cart</h4>
      </div>

      <div className="stores-Modal-content">
        <CartItem item={cart.lastAddedItem} readOnly={true} isInventoryStore={isInventoryStore} />

        <div className="added-Product-modal-summary">
          <div className="added-Product-modal-summary-items">
            {`${quantity} item${quantity !== 1 ? 's' : ''} in your cart`}
          </div>
          <div className="added-Product-modal-summary-subtotal">
            Sub-total: {total}
          </div>
        </div>
      </div>

      <div className="stores-Modal-footer added-Product-modal-actions">
        <a
          className="sb-Btn sb-Btn--primaryBlack sb-Btn--responsive"
          href={Routes.store_cart_path(storeId())}
        >
          Go to Cart
        </a>
        <a
          className="sb-Btn sb-Btn--secondaryBlack sb-Btn--responsive"
          href={Routes.store_products_path(storeId())}
        >
          Continue Shopping
        </a>
      </div>
      <RecommendedProductsContainer context={'modal'} />
    </>
  );
};

ProductAdded.propTypes = {
  cart: PropTypes.shape({
    items: CartItemsPropTypes,
    quantity: PropTypes.number,
    total: PropTypes.string
  }),
  lastProductAdded: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    variantName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    sizeDisplayName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  })
};

const mapStateToProps = state => {
  return {
    cart: state.cart,
    lastProductAdded: state.lastAddedCartItem
  };
};

export default connect(mapStateToProps)(ProductAdded);
