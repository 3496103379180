import React from 'react';
import PropTypes from 'prop-types';

const changeQty = (value, min, max, onChange) => {
  const newValue = parseInt(value);
  onChange(newValue);
};

const Stepper = ({
  min = 0,
  max = 99999,
  readOnly = true,
  value,
  onChange
}) => {
  const disabledDecrement = value <= min;
  const disabledIncrement = value >= max;
  const disabledDecrementClass = disabledDecrement
    ? 'stores-Stepper-button--disabled'
    : '';
  const disabledIncrementClass = disabledIncrement
    ? 'stores-Stepper-button--disabled'
    : '';
  const handleFocus = e => {
    if (!readOnly) e.target.select();
  };

  return (
    <div className="sb-Form stores-Stepper">
      <span
        className={`stores-Stepper-button stores-Stepper-decrement ${disabledDecrementClass}`}
        onClick={() =>
          !disabledDecrement && changeQty(value - 1, min, max, onChange)
        }
      >
        −
      </span>

      <input
        className="sb-Form-stepper stores-Stepper-input"
        value={value}
        min={min}
        max={max}
        type="number"
        readOnly={readOnly}
        onChange={e => changeQty(e.target.value, min, max, onChange)}
        onFocus={handleFocus}
      />

      <span
        className={`stores-Stepper-button stores-Stepper-increment ${disabledIncrementClass}`}
        onClick={() =>
          !disabledIncrement && changeQty(value + 1, min, max, onChange)
        }
      >
        +
      </span>
    </div>
  );
};

Stepper.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.number
};

export default Stepper;
