import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { required, email, length, format } from 'redux-form-validators';

import Summary from 'components/Purchase/Summary';
import { Button } from 'components/elements';
import { FormGroup, FormGroupDisplay } from 'components/Purchase/FormGroup';

const ContactDisplay = ({
  contact: { first_name, last_name, email, phone_number }
}) => (
  <div>
    <strong>
      {first_name} {last_name}
    </strong>
    <br />
    {email}
    <br />
    {phone_number}
  </div>
);

// PENDING: [Phelan Lucky] temporarily hard-coded, either remove or make useful once fundraiser is over
const PhelanLuckyMarketingOptIn = (purchase) => {
  if (purchase.showProfitBar) {
    return (
      <div className="sb-Form-group--buttons">
      <label className="sb-Form-radio">
        <Field name="purchase.accepts_marketing" component="input" type="checkbox" className="sb-Form-group" />
        <span className="sb-Form-indicator" />
        Opt-in to hear more from the Phelan Lucky campaign and Custom Ink! You may opt-out at any time. See our
        <a href="https://www.customink.com/about/privacy" target="_blank"> privacy policy</a> for more info.
      </label>
    </div>
    )
  }
}

const contactNameFields = purchase => {
  if (purchase.customContactNameFields) {
    return (
      <>
        <Field
          name="purchase.first_name"
          type="text"
          component={FormGroup}
          label="Full Name"
          validate={required()}
          className="sb-Form-group--mr"
          autoComplete="name"
          placeholder="John Doe"
        />
        <Field
          name="purchase.last_name"
          type="text"
          component={FormGroup}
          label="Team Name"
          validate={required()}
          placeholder="Shooting Stars - 16U Elias"
        />
      </>
    );
  } else {
    return (
      <>
        <Field
          name="purchase.first_name"
          type="text"
          component={FormGroup}
          label="First Name"
          validate={required()}
          className="sb-Form-group--mr"
          autoComplete="given-name"
        />
        <Field
          name="purchase.last_name"
          type="text"
          component={FormGroup}
          label="Last Name"
          validate={required()}
          autoComplete="family-name"
        />
      </>
    );
  }
};

const Contact = ({ editing, contactAttributes, handleSubmit, submitting, purchase }) => {
  if (!editing) {
    return <ContactDisplay contact={contactAttributes} />;
  }

  return (
    <React.Fragment>
      <div className="sb-Util-visible--sm">
        <Summary />
      </div>

      <form className="sb-Form" onSubmit={handleSubmit}>
        <div className="sb-Form-group sb-Form--inline--flex">
          {contactNameFields(purchase)}
        </div>
        <div className="sb-Form-group sb-Form--inline--flex">
          <Field
            name="purchase.email"
            type="email"
            component={FormGroup}
            label="Email"
            validate={required()}
            className="sb-Form-group--mr"
            autoComplete="email"
          />
          <Field
            name="purchase.phone_number"
            type="tel"
            component={FormGroup}
            label="Phone Number"
            validate={[
              required(),
              length({ minimum: 10 }),
              format({
                with: /^\+?[\d \-()]+$/i,
                message: 'can only contain numbers, spaces and the + and - characters'
              })
            ]}
            autoComplete="tel"
          />
        </div>
        <div className="sb-Form-group sb-Form--inline--flex">
          {PhelanLuckyMarketingOptIn(purchase)}
        </div>

        <div className="stores-PurchaseForm-actions sb-Form-group sb-Form-group--buttons">
          {editing && (
            <Button
              htmlType="submit"
              className="primaryBlack"
              disabled={submitting}
            >
              Next
            </Button>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

Contact.propTypes = {
  contactAttributes: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    accepts_marketing: PropTypes.bool
  }),
  editing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const selector = formValueSelector('purchase');

  return {
    contactAttributes: selector(state, 'purchase'),
    purchase: state.purchase
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'purchase',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnBlur: false
  })(Contact)
);
