import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';

const ProductBadge = ({ hidden, text, className }) => {
  if (hidden) return null;

  const defaultClassName = 'stores-ProductBadge';

  return (
    <>
      <div
        className={classnames(
          defaultClassName,
          `${defaultClassName}--${kebabCase(text)}`,
          className
        )}
      >
        <div className={`${defaultClassName}-text`}>{text}</div>
      </div>
    </>
  );
};

ProductBadge.propTypes = {
  hidden: PropTypes.bool,
  text: PropTypes.string
};

export default ProductBadge;
