import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';
import 'flickity-as-nav-for';

const flickityOptions = asNavFor => ({
  setGallerySize: false,
  pageDots: false,
  cellAlign: 'center',
  contain: asNavFor,
  lazyLoad: 5,
  prevNextButtons: !asNavFor,
  asNavFor: asNavFor ? '.sb-CarouselProofContent.' + asNavFor : false
});

const Carousel = ({ images, asNavFor, flickityClass }) => {
  const classPrefix = asNavFor ? 'sb-CarouselProofNav' : 'sb-CarouselProofContent';

  return (
    <>
      <Flickity
        className={`sb-CarouselProof ${classPrefix} ${
          flickityClass ? flickityClass : ''
        }`}
        elementType={'div'}
        options={flickityOptions(asNavFor)}
        reloadOnUpdate={true}
      >
        {images.map((image, idx) => (
          <div
            className={`sb-CarouselProof-cell ${classPrefix}-cell`}
            key={`${idx}-${image.source}`}
          >
            <img
              className={`sb-CarouselProof-img ${classPrefix}-img`}
              data-flickity-lazyload={asNavFor ? image.thumbnail : image.source}
            />
          </div>
        ))}
      </Flickity>
    </>
  );
};

const CarouselWrapper = ({ images, withNavigationClass }) => (
  <React.Fragment>
    <Carousel images={images} flickityClass={withNavigationClass} />
    {images.length > 1 && withNavigationClass && (
      <Carousel images={images} asNavFor={withNavigationClass} />
    )}
  </React.Fragment>
);

CarouselWrapper.propTypes = {
  images: PropTypes.array.isRequired,
  withNavigation: PropTypes.bool
};

export default CarouselWrapper;
