import { SubmissionError } from 'redux-form';
import axios from 'axios';

import { updateToken } from 'actions/payment';
import { changePurchase } from 'actions/purchase';
import Routes from 'lib/routes';
import { metrics } from 'utils/metrics';
import storeId from 'utils/storeId';

async function checkout(path_prefix, values) {
  const csrfToken = document.querySelector('[name="csrf-token"]').content;
  const config = {
    headers: {
      'x-requested-with': 'XMLHttpRequest'
    }
  };

  try {
    const response = await axios.post(
      Routes[`${path_prefix}store_purchases_path`](storeId()),
      values,
      config
    );

    return response;
  } catch (error) {
    return error.response;
  }
}

async function contact(values, dispatch) {
  // XXX: Remove shipping_address attributes to make sure contact step only validates contact related fields
  values = { ...values, purchase: { ...values.purchase } };
  delete values.purchase.shipping_address;

  const { data } = await purchaseFunctions.checkout('contact_', {
    purchase: values.purchase
  });

  throwOnError(data, dispatch, () => {
    metrics.pageViewTag(`/os/${storeId()}/checkout/contact/error`);
  });

  metrics.pageViewTag(`/os/${storeId()}/checkout/contact`);

  return data;
}

async function shipping(values, dispatch) {
  if (values.shipping.method === 'individual') {
    values.purchase.location_id = null;
  } else {
    delete values.purchase.shipping_address;
    values.purchase.location_id = values.shipping.locationId;
  }

  const { data } = await purchaseFunctions.checkout('shipping_', {
    purchase: values.purchase
  });

  throwOnError(data, dispatch, () => {
    metrics.pageViewTag(`/os/${storeId()}/checkout/shipping/error`);
  });

  metrics.pageViewTag(`/os/${storeId()}/checkout/shipping`);

  return data;
}

async function voucher(values, dispatch) {
  const { data } = await purchaseFunctions.checkout('voucher_', {
    purchase: values.purchase
  });

  throwOnError(data, dispatch);

  return data;
}

async function payment(values, dispatch) {
  const { data } = await purchaseFunctions.checkout('', values);

  throwOnError(data, dispatch, () => {
    if (data.payment) {
      dispatch(updateToken(data.payment.token));
    }
    metrics.pageViewTag(`/os/${storeId()}/checkout/payment/error`);
  });

  metrics.pageViewTag(`/os/${storeId()}/checkout/payment`);

  return (window.location = Routes.store_purchase_path(
    storeId(),
    data.purchase.secure_id
  ));
}

function throwOnError(data, dispatch, optHandler = () => {}) {
  if (data.errors) {
    if (data.purchase) {
      dispatch(changePurchase(data.purchase));
    }

    optHandler();

    throw new SubmissionError(data.errors);
  }
}

const purchaseFunctions = { checkout, contact, shipping, voucher, payment };

export default purchaseFunctions;
