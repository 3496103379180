export const UPDATE_PURCHASE_CONFIRMATION = 'UPDATE_PURCHASE_CONFIRMATION';

const initialState = {
  purchaseConfirmation: {}
};

const purchaseConfirmation = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PURCHASE_CONFIRMATION:
      return {
        ...action.data.purchaseConfirmation
      };
    default:
      return state;
  }
};

export default purchaseConfirmation;
