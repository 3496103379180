import React from 'react';
import PropTypes from 'prop-types';
import { CartItemPropTypes } from 'utils/ReusablePropTypes';
import storeId from 'utils/storeId';
import Routes from 'lib/routes';
import Stepper from 'components/elements/Stepper';

import { connect } from 'react-redux';
import removeCartItem from 'services/removeCartItem';
import updateCartItem from 'services/updateCartItem';

const CartItem = ({ dispatch, item, readOnly, isInventoryStore }) => {
  const {
    deliveryEstimate,
    fulfillmentDate,
    id,
    imageUrl,
    internationalDeliveryInfo,
    minQuantity,
    name,
    price,
    quantity,
    sizeDisplayName,
    undecorated,
    variantName
  } = item;

  const update = newQuantity => {
    updateCartItem(dispatch, { ...item, quantity: newQuantity });
  };

  const remove = e => {
    e.preventDefault();
    removeCartItem(dispatch, { ...item });
  };

  return (
    <section className="cart-Item">
      <div className="cart-Item--productImage">
        <img src={imageUrl} />
      </div>
      <div className="cart-Item--productDetails">
        <p>
          <a href={Routes.store_product_path(storeId(), id)}>
            <strong className="cart-Item--name">{name}</strong>
          </a>
          <span className="cart-Item--price sb-Util-hidden--sm">{price}</span>
        </p>

        {variantName && (
          <p>
            <strong>Style:</strong> {variantName} - {sizeDisplayName}
          </p>
        )}

        <div className="cart-Item--quantity">
          <strong>
            Quantity: {minQuantity > 1 && `(Minimum ${minQuantity})`}
          </strong>
          {readOnly ? (
            quantity
          ) : (
            <Stepper value={quantity} onChange={update} min={minQuantity} />
          )}
        </div>

        <p className="sb-Util-visible--sm">{price}</p>
        {!isInventoryStore && (
          <p className="sb-Util-hidden--sm">
          <span>
            {undecorated ? (
              <>
                Ships on {fulfillmentDate}, delivery within {deliveryEstimate}
              </>
            ) : (
              <>
                Estimated delivery:
                <span className="stores-Text--alert"> {deliveryEstimate}</span>
              </>
            )}
            {internationalDeliveryInfo && (
              <>
                <br />
                {internationalDeliveryInfo}
              </>
            )}
          </span>
          </p>
        )}
        {!readOnly && (
          <a href="#" onClick={remove}>
            Remove
          </a>
        )}
      </div>
    </section>
  );
};

CartItem.propTypes = {
  dispatch: PropTypes.func,
  item: PropTypes.shape(CartItemPropTypes),
  readOnly: PropTypes.bool,
  isInventoryStore: PropTypes.bool
};

export default connect()(CartItem);
