import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'components/elements';
import AddressBlock from 'components/AddressBlock';

import { ShippingAddressPropTypes } from 'utils/ReusablePropTypes';

const ConfirmAddress = ({
  suggestedAddress,
  currentAddress,
  onSubmit,
  onClose,
  close
}) => {
  const handleSubmit = address => () => {
    onSubmit(address);
    close();
  };

  return (
    <>
      <div className="stores-ConfirmAddress-header stores-Modal-header">
        <h1>
          {suggestedAddress ? 'Confirm your address' : 'Address not verified'}
        </h1>
      </div>

      {suggestedAddress ? (
        <>
          <div className="stores-Modal-content">
            <p>
              To make sure you receive your order on time,{' '}
              <br className="sb-Util-visible--md" />
              we suggest using the following shipping address:
            </p>

            <div className="stores-ConfirmAddress-address">
              <div className="stores-ConfirmAddress-verifiedLabel">
                Verified for delivery
              </div>
              <AddressBlock address={suggestedAddress} />
            </div>
          </div>

          <div className="stores-ConfirmAddress-actions stores-Modal-footer">
            <Button
              className="sb-Btn--mr"
              type="secondaryBlack"
              onClick={handleSubmit(currentAddress)}
            >
              Use Existing Address
            </Button>

            <Button onClick={handleSubmit(suggestedAddress)}>
              Accept Address
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="stores-Modal-content">
            <p>
              We were not able to find and verify your shipping address.{' '}
              <br className="sb-Util-visible--md" />
              Please certify that your address is correct.
            </p>

            <div className="stores-ConfirmAddress-address">
              <AddressBlock address={currentAddress} />
            </div>
          </div>

          <div className="stores-ConfirmAddress-actions stores-Modal-footer">
            <Button
              className="sb-Btn--mr"
              type="secondaryBlack"
              onClick={onClose || close}
            >
              Edit Address
            </Button>

            <Button onClick={handleSubmit(currentAddress)}>
              My Address is Correct
            </Button>
          </div>
        </>
      )}
    </>
  );
};

ConfirmAddress.propTypes = {
  currentAddress: ShippingAddressPropTypes.isRequired,
  suggestedAddress: ShippingAddressPropTypes,
  onSubmit: PropTypes.func.isRequired,
  close: PropTypes.func
};

export default ConfirmAddress;
