import axios from 'axios';

import { updateCartItem } from 'actions/cart';
import storeId from 'utils/storeId';
import Routes from 'lib/routes';

export default async function(dispatcher, product = {}) {
  try {
    const response = await axios.put(
      Routes.store_cart_path(storeId()),
      product
    );
    const { cart } = response.data;
    dispatcher(updateCartItem(cart));
  } catch (error) {
    // TODO: Handle error messaging
    console.error(error.toString());
  }
}
