import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { normalize } from 'utils/images';

const sizesSelector = (state, props) => props.product.sizes;

const getCurrentSize = (state, props) => {
  const selector = formValueSelector('bundleProducts');
  return selector(state, `products[${props.productIndex}].sizeName`);
};

export const getCurrentSizeLabel = createSelector(
  sizesSelector,
  getCurrentSize,
  (sizes, size) => {
    const currentSize = sizes.find(vSize => vSize.value === size);
    return currentSize ? currentSize.label : '';
  }
);

const variantsSelector = (state, props) => props.product.variants;

const variantIdSelector = (state, props) => {
  const selector = formValueSelector('bundleProducts');
  return selector(state, `products[${props.productIndex}].variantId`);
};

export const getCurrentVariant = createSelector(
  variantsSelector,
  variantIdSelector,
  (variants, variantId) => {
    const variant = variants.find(variant => variant.id === variantId);

    return variant || variants[0];
  }
);

const variantImagesSelector = state =>
  state.bundleProducts.products
    .map((product, index) => {
      const selectorProps = { product: product, productIndex: index };
      const variant = getCurrentVariant(state, selectorProps);
      return variant.images;
    })
    .flat();

export const getBundleImages = createSelector(
  variantImagesSelector,
  variantImages => normalize([...variantImages])
);
