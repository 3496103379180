import axios from 'axios';
import storeId from 'utils/storeId';
import Routes from 'lib/routes';

export default async function(values) {
  try {
    const results = await axios.get(
      Routes.store_manage_location_dashboard_search_path(storeId(), {
        search_query: values.searchQuery,
        locations: values.location
      })
    );

    return results;
  } catch (error) {
    console.log('error');
  }
}
