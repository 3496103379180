import $ from 'jquery';

$(function() {
  let header = $('.stores-Header-topBar');

  if (header.length > 0) {
    window.onscroll = function() {
      if (window.pageYOffset > 0) {
        header.addClass('sticky');
      } else {
        header.removeClass('sticky');
      }
    };
  }
});
