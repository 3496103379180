export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';

const initialState = {
  total: 0,
  quantity: 0,
  items: {}
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CART_ITEM:
      return {
        ...action.data.cart
      };
    case UPDATE_CART_ITEM:
      return {
        ...action.data.cart
      };
    case REMOVE_CART_ITEM:
      return {
        ...action.data.cart
      };
    default:
      return state;
  }
};

export default cart;
