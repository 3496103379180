import * as actions from 'reducers/purchaseConfirmation';

export function updatePurchaseConfirmation(purchaseConfirmation) {
  return {
    type: actions.UPDATE_PURCHASE_CONFIRMATION,
    data: {
      purchaseConfirmation
    }
  };
}
