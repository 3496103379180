import React from 'react';
import PropTypes from 'prop-types';

const SizeLineup = ({ handleOpenSizingLineup, sizeLineupURL }) => {
  return (
    <div>
      <a href={sizeLineupURL} rel="popup" onClick={handleOpenSizingLineup}>
        Sizing Guide
      </a>
    </div>
  );
};

SizeLineup.propTypes = {
  handleOpenSizingLineup: PropTypes.func.isRequired,
  sizeLineupURL: PropTypes.string.isRequired
};

export default SizeLineup;
