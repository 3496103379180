import React from 'react';

const TimeLeft = ({ limited, timeLeftToOrder }) => (
  <React.Fragment>
    {limited && (
      <div className="stores-ProductDetails-timeLeftToOrder stores-Text--alert">
        {timeLeftToOrder}
      </div>
    )}
  </React.Fragment>
);

export default TimeLeft;
