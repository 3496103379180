import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ type, title, text }) => {
  return (
    <React.Fragment>
      <div className={`sb-Alert sb-Alert--${type}`}>
        <div className="sb-Alert-icon">
          <i className={`sb-Icon--${type}`} />
        </div>
        <div className="sb-Alert-message">
          {title && <h3>{title}</h3>}
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    </React.Fragment>
  );
};

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string
};

export default Alert;
