import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  PigmentThemeProvider,
  LinearProgress,
  Typography
} from '@customink/pigment-react';

const progressBarPercentage = (goal, totalProfit) => {
  if (!totalProfit || !goal) return 0;
  const progressPercent = (totalProfit / goal) * 100;

  return Math.min(progressPercent, 100);
};

const campaignDaysLeft = time => {
  if (!time) return '';
  if (time.includes('Less than an hour')) return time.toLowerCase();

  const timeArray = time.split(' ');
  timeArray.splice(1, 0, 'more');
  const timeLeft = timeArray.join(' ');
  return timeLeft;
};

const ProgressBar = ({ profit, store }) => {
  const goal = profit.goal;
  const totalProfit = profit.total_profit;
  const totalSupporters = profit.total_supporters || 0;
  const current_campaign = store.campaigns[0];

  return (
    <PigmentThemeProvider>
      <div className="tracker-sidebar">
        <div className="tracker-wrapper">
          <div className="tracker">
            <div className="tracker-progressBar">
              <div className="progressBar">
                <Typography
                  variant="h4"
                  fontSize={20}
                  className="tracker-progressBar-status"
                >
                  {`${totalSupporters} ${
                    totalSupporters === 1 ? 'supporter' : 'supporters'
                  }`}
                </Typography>
                <div className="progressBar-progress">
                  <LinearProgress
                    variant="determinate"
                    value={progressBarPercentage(goal, totalProfit)}
                  />
                </div>
                <div className="progressBar-content">
                  <Typography variant="captionSmall" fontSize={14}>
                    {totalProfit ? `$${totalProfit.toLocaleString()}` : '$0.00'}{' '}
                    <span className="goalAmount">{`/ $${goal.toLocaleString()} goal`}</span>
                  </Typography>
                </div>
              </div>
              <div className="content">
                <div className="tracker-status">
                  <div className="closeDate">
                    <Typography variant="captionSmall" fontSize={13}>
                      <div className="closeDate-title">Order Close Date:</div>
                      {moment(current_campaign?.closes_at)
                        .endOf('day')
                        .format('dddd, MMMM D, YYYY')}
                    </Typography>
                    {store.status !== 'closed' ? (
                      <Typography
                        aria-labelledby="days"
                        className="daysLeft"
                        variant="captionSmall"
                        fontSize={13}
                      >
                        Open for{' '}
                        {campaignDaysLeft(
                          current_campaign?.time_remaining_in_words
                        )}
                      </Typography>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PigmentThemeProvider>
  );
};

const mapStateToProps = state => ({ profit: state.profit, store: state.store });

ProgressBar.propTypes = {};

export default connect(mapStateToProps)(ProgressBar);
