import React from 'react';
import PropTypes from 'prop-types';

import ReactPaginate from 'react-paginate';
import LeftChevron from 'images/icons/chevron_left.svg';
import RightChevron from 'images/icons/chevron_right.svg';

const Paginate = props => {
  return (
    <ReactPaginate
      containerClassName="admin-Pagination"
      pageClassName="admin-Pagination-page"
      pageLinkClassName="admin-Pagination-pageLink"
      activeLinkClassName="admin-Pagination-pageLink--active"
      breakClassName="admin-Pagination-break"
      breakLinkClassName="admin-Pagination-breakLink"
      previousClassName="admin-Pagination-previous"
      previousLinkClassName="admin-Pagination-previousLink"
      nextLinkClassName="admin-Pagination-nextLink"
      nextClassName="admin-Pagination-next"
      previousLabel={<LeftChevron />}
      nextLabel={<RightChevron />}
      breakLabel={'...'}
      pageCount={props.totalPages}
      marginPagesDisplayed={2}
      // Convert from 1-index (standard for kaminari) to 0-index
      forcePage={props.currentPage - 1}
      onPageChange={e => {
        props.pageClick(e.selected + 1);
      }}
    />
  );
};

Paginate.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageClick: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default Paginate;
