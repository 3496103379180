import * as actions from 'reducers/cart';

export function addCartItem(cart, currentItem) {
  return {
    type: actions.ADD_CART_ITEM,
    data: {
      cart,
      currentItem
    }
  };
}

export function updateCartItem(cart) {
  return {
    type: actions.UPDATE_CART_ITEM,
    data: {
      cart
    }
  };
}

export function removeCartItem(cart) {
  return {
    type: actions.REMOVE_CART_ITEM,
    data: {
      cart
    }
  };
}
