import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LocationPropTypes } from 'utils/ReusablePropTypes';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import storeId from 'utils/storeId';
import Routes from 'lib/routes';
import ItemStatusesSearchResults from 'components/ItemStatusesSearchResults';
import Button from 'components/elements/Button';
import itemStatusesSearch from 'services/itemStatusesSearch';

import { updateStatuses } from 'actions/itemStatuses';

class LocationDashboard extends Component {
  state = {
    lastSearchQuery: '',
    timeframe: null
  };

  search = async values => {
    const { dispatch } = this.props;
    const { data } = await itemStatusesSearch(values);

    this.setState({
      lastSearchQuery: values.searchQuery,
      timeframe: data.timeframe
    });
    dispatch(updateStatuses(data));
  };

  render() {
    const {
      handleSubmit,
      itemStatuses,
      locations,
      selectedLocation
    } = this.props;

    return (
      <div className="stores-LocationDashboard">
        <div className="stores-LocationDashboard-header">
          <div className="sb-Wrapper">
            <div className="stores-LocationDashboard-headerContent">
              <h1 className="stores-LocationDashboard-headerPageHeading">
                Delivery Checklist
              </h1>
              <h4>Manage & organize your deliveries.</h4>
              <div className="stores-LocationDashboard-searchHelpText">
                Search by email address, order number, package number, or
                product name
              </div>

              <div>
                <form
                  className="sb-Form sb-Form--inline stores-LocationDashboard-searchForm"
                  onSubmit={handleSubmit(this.search)}
                >
                  <div className="sb-Form-group sb-Form-group--large stores-LocationDashboard-searchFormInput">
                    <Field
                      name="searchQuery"
                      type="text"
                      component="input"
                      placeholder="Search"
                      className="stores-LocationDashboard-searchFormInput-icon"
                    />
                  </div>
                  <div className="sb-Form-group sb-Form-group--large stores-LocationDashboard-searchFormInput">
                    <Field name="location" component="select">
                      <option value="all">All Locations</option>
                      {locations.map((location, index) => (
                        <option key={index} value={location.id}>
                          {location.locationName}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="sb-Form-group sb-Form-group--buttons stores-LocationDashboard-searchFormSubmit">
                    <Button type="large sb-Btn--primaryBlack" htmlType="submit">
                      Search
                    </Button>
                  </div>
                </form>
              </div>
              <a
                className="sb-Btn sb-Btn--responsive sb-Btn--primaryBlack sb-Btn--large"
                href={Routes.store_manage_location_dashboard_download_path(
                  storeId(),
                  { locations: selectedLocation, format: 'csv' }
                )}
              >
                Download CSV
              </a>
            </div>
          </div>
        </div>

        <ItemStatusesSearchResults
          itemStatuses={itemStatuses}
          lastSearchQuery={this.state.lastSearchQuery}
          timeframe={this.state.timeframe}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const selector = formValueSelector('locationDashboard');
  const params = new URLSearchParams(window.location.search);
  const defaultLocation = params.get('location') || 'all';

  return {
    initialValues: { location: defaultLocation },
    itemStatuses: state.itemStatuses,
    locations: state.store.locations,
    selectedLocation: selector(state, 'location')
  };
};

LocationDashboard.propTypes = {
  initialValues: PropTypes.object.isRequired,
  itemStatuses: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape(LocationPropTypes)).isRequired
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'locationDashboard' })(LocationDashboard)
);
