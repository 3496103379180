export const CHANGE_PURCHASE = 'CHANGE_PURCHASE';

const initialState = {
  purchase: {}
};

const purchase = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PURCHASE:
      return {
        ...action.data.purchase
      };
    default:
      return state;
  }
};

export default purchase;
