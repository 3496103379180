import React from 'react';
import { Stepper } from 'components/elements';

export default ({ input, label, max, min, readOnly }) => (
  <div className="sb-Form-group">
    {label && <h2>{label}</h2>}
    <div>
      <Stepper
        min={min}
        max={max}
        onChange={input.onChange}
        readOnly={readOnly}
        value={input.value || 0}
      />
    </div>
  </div>
);
