import PropTypes from 'prop-types';

export const BundlePropTypes = {
  name: PropTypes.string,
  deliveryMessageOverride: PropTypes.string,
  description: PropTypes.string
};

export const BundleProductsPropTypes = {
  fulfillmentDate: PropTypes.string,
  limited: PropTypes.bool,
  price: PropTypes.string,
  timeLeftToOrder: PropTypes.string
};

export const CartItemPropTypes = {
  id: PropTypes.number,
  color: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  quantity: PropTypes.number,
  size: PropTypes.string,
  sizeDisplayName: PropTypes.string,
  deliveryEstimate: PropTypes.string
};

export const CartItemsPropTypes = PropTypes.objectOf(
  PropTypes.shape(CartItemPropTypes)
);

export const ImagePropTypes = {
  source: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ embed: PropTypes.string })
  ]),
  thumbnail: PropTypes.string
};

export const PurchaseOptionsPropTypes = PropTypes.objectOf(
  PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    shipping: PropTypes.string.isRequired,
    subtotal: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired
  })
);

export const PurchaseConfirmationPropTypes = {
  active: PropTypes.bool.isRequired,
  cancelable: PropTypes.bool.isRequired,
  canceled: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number,
  itemFulfillmentGroupsCount: PropTypes.number.isRequired,
  lastName: PropTypes.string.isRequired,
  logo: PropTypes.string,
  paymentMethod: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  placedOn: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired,
  refundAmount: PropTypes.string,
  refundTimeframe: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  shipments: PropTypes.arrayOf(
    PropTypes.shape(PurchaseConfirmationShipmentPropTypes)
  ).isRequired,
  shippable: PropTypes.bool.isRequired,
  shippingAddress: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  }).isRequired
};

const PurchaseConfirmationShipmentPropTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape(PurchaseConfirmationShipmentItemPropTypes)
  ).isRequired,
  number: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired
};

const PurchaseConfirmationShipmentItemPropTypes = {
  quantity: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  variantName: PropTypes.string.isRequired
};

export const ShippingAddressPropTypes = PropTypes.shape({
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  address_1: PropTypes.string,
  address_2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postal_code: PropTypes.string,
  country: PropTypes.string
});

export const ProductPropTypes = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(ProductImagePropTypes),
  limited: PropTypes.bool,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  sizeChart: PropTypes.string,
  sizeLineup: PropTypes.string,
  sizes: VariantSizePropTypes,
  styleId: PropTypes.number.isRequired,
  timeLeftToOrder: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape(VariantPropTypes)).isRequired
};

export const ProductsPropTypes = PropTypes.arrayOf(
  PropTypes.shape(ProductPropTypes)
);

const ProductImagePropTypes = PropTypes.shape({
  url: PropTypes.string
});

const RecommendedProductPropTypes = PropTypes.shape({
  href: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired
});

export const RecommendedProductsPropTypes = PropTypes.arrayOf(
  RecommendedProductPropTypes
);

export const VariantSizePropTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export const VariantSizesPropTypes = PropTypes.arrayOf(
  PropTypes.shape(VariantSizePropTypes)
);

export const LocationPropTypes = {
  id: PropTypes.number.isRequired,
  locationName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired
};

export const VariantPropTypes = {
  id: PropTypes.number.isRequired,
  colorId: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(ProductImagePropTypes),
  name: PropTypes.string.isRequired,
  sizes: VariantSizesPropTypes.isRequired,
  storeProductId: PropTypes.number
};

export const ShippingCountriesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    states: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    )
  })
);
