import React from 'react';
import { connect } from 'react-redux';

import Carousel from './Carousel';
import { getProductImages } from 'selectors/product';

const ProductImages = ({ images, videos, navigationClass }) => (
  <Carousel images={images} videos={videos} withNavigationClass={navigationClass} />
);

const mapStateToProps = state => ({
  images: getProductImages(state),
  videos: state.product.videos
});

export default connect(mapStateToProps)(ProductImages);
