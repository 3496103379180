export const ADD_RECOMMENDED_PRODUCTS = 'ADD_RECOMMENDED_PRODUCTS';

const recommendedProducts = (state = [], action) => {
  switch (action.type) {
    case 'ADD_RECOMMENDED_PRODUCTS':
      return action.data;
    default:
      return state;
  }
};

export default recommendedProducts;
