export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

const initialState = {
  name: null,
  config: null
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { name: action.data.name, config: action.data.config };
    case 'CLOSE_MODAL':
      return { name: null, config: null };
    default:
      return state;
  }
};

export default modal;
