import React from 'react';
import { connect } from 'react-redux';

import { ProductsPropTypes } from 'utils/ReusablePropTypes';
import ProductCard from 'components/ProductCard';

const ProductsContainer = ({ products }) => {
  return (
    <div className="stores-productsContainer">
      {products.map(product => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};

const mapStateToProps = state => ({ products: state.products });

ProductsContainer.propTypes = {
  products: ProductsPropTypes
};

export default connect(mapStateToProps)(ProductsContainer);
