export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_BRAINTREE = 'UPDATE_BRAINTREE';

const initialState = {
  token: null,
  braintree: null
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_TOKEN':
      return { ...state, token: action.data.token };
    case 'UPDATE_BRAINTREE':
      return { ...state, braintree: action.data.braintree };
    default:
      return state;
  }
};

export default payment;
