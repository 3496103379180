import React from 'react';
import { Provider } from 'react-redux';
import PurchaseConfirmationPage from './PurchaseConfirmationPage';

const ConnectedPurchaseConfirmationPage = props => (
  <Provider store={ReactOnRails.getStore('appStore')}>
    <PurchaseConfirmationPage />
  </Provider>
);

export default ConnectedPurchaseConfirmationPage;
