import React from 'react';
import { ProductColorSwatch } from '@customink/react_components';

import { CustomSelect } from 'components/FormGroups';

const ColorSelect = ({ currentVariant, input, meta, variants }) => {
  const options = variants.map(variant => ({
    id: variant.id,
    value: variant.id,
    className: 'stores-ColorSwatches-swatch',
    disabled: false,
    data: {
      rgb: variant.rgb,
      trimRgb: variant.trimRgb
    }
  }));

  const label = (
    <span>
      Color: <span style={{ fontWeight: '100' }}>{currentVariant.name}</span>
    </span>
  );

  return (
    <CustomSelect
      $component={ProductColorSwatch}
      className="stores-ColorSwatches"
      input={input}
      label={label}
      meta={meta}
      options={options}
    />
  );
};

export default ColorSelect;
