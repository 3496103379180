import React from 'react';
import { SizeBox } from 'components/elements';
import { CustomSelect } from 'components/FormGroups';

const AmountSelect = ({ amounts, input, meta }) => {
  let options = amounts.map((amount, index) => ({
    id: amount.value ? amount.value : 0,
    flex: true,
    value: amount.value,
    data: {
      size: amount.display
    }
  }));

  options = options.length > 1 ? options : [];

  const label = (
    <span>
      Amount:
    </span>
  );
  return (
    <CustomSelect
      className="stores-DonationProductDetails-amountSelector"
      $component={SizeBox}
      input={input}
      label={label}
      meta={meta}
      options={options}
      flex={true}
    />
  );
};

export default AmountSelect;
