import React from 'react';
import { Provider } from 'react-redux';
import Cart from 'components/Cart';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const ConnectedCart = props => (
  <Provider store={ReactOnRails.getStore('appStore')}>
    <Cart />
  </Provider>
);

export default ConnectedCart;
