import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { hasErrors, renderError } from 'helpers/FormHelpers';

import CloudUpload from 'images/icons/cloud_upload.svg';

const Dropzone = React.forwardRef((props, ref) => {
  const [highlight, setHighlight] = React.useState(false);
  const fileListToArray = list => {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  };

  const handleDragLeave = () => {
    setHighlight(false);
  };

  const handleDragOver = evt => {
    evt.preventDefault();
    setHighlight(true);
  };

  const handleDrop = evt => {
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (props.onFilesAdded) {
      ref.current.files = files;
      props.onFilesAdded(fileListToArray(files));
    }
    setHighlight(false);
  };

  const handleFilesAdded = evt => {
    const files = evt.target.files;
    if (props.onFilesAdded) {
      props.onFilesAdded(fileListToArray(files));
    }
  };

  const openFileDialog = () => {
    ref.current.click();
  };

  const dropzoneClass = classnames({
    'stores-Dropzone': true,
    highlight: highlight,
    'is-fieldWithErrors': hasErrors(props.id, props.errors)
  });

  return (
    <React.Fragment>
      <div
        className={dropzoneClass}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={openFileDialog}
      >
        <CloudUpload className="stores-Dropzone-icon" />
        <input
          ref={ref}
          name={props.name}
          className="stores-Dropzone-fileInput"
          type="file"
          onChange={handleFilesAdded}
          accept={'.jpeg, .png'}
        />
        <span>
          Drag and drop or <span style={{ color: '#1e39d2' }}> browse </span>{' '}
          your computer.
        </span>
      </div>
      {renderError(props.id, props.errors)}
    </React.Fragment>
  );
});

Dropzone.propTypes = {
  onFilesAdded: PropTypes.func,
  id: PropTypes.string,
  errors: PropTypes.object
};

export default Dropzone;
