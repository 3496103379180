/*
File generated by js-routes 1.4.4
Based on Rails routes of Stores::Application
 */

(function() {
  var DeprecatedBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, Utils, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  function ParameterMissing(message, fileName, lineNumber) {
    var instance = new Error(message, fileName, lineNumber);
    if(Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  }

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf){
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  SpecialOptionsKey = "_options";

  DeprecatedBehavior = false;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: process.env.RAILS_RELATIVE_URL_ROOT || "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = DeprecatedBehavior || actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          if (value != null) {
            delete parameters[left];
            return this.path_identifier(value);
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, "") !== left) {
        route[1] = left = left.replace(/^\*/i, "");
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace.split(".");
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// add_api_store_cart => /api/stores/:store_id/cart/add(.:format)
  // function(store_id, options)
  add_api_store_cart_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"cart",false],[2,[7,"/",false],[2,[6,"add",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_bypass_store_access_code => /:store_id/access_code/admin_bypass(.:format)
  // function(store_id, options)
  admin_bypass_store_access_code_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"access_code",false],[2,[7,"/",false],[2,[6,"admin_bypass",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_role => /admin/roles/:id(.:format)
  // function(id, options)
  admin_role_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_root => /admin(.:format)
  // function(options)
  admin_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_store_roles => /admin/stores/:store_id/roles(.:format)
  // function(store_id, options)
  admin_store_roles_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_stores => /admin/stores(.:format)
  // function(options)
  admin_stores_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"stores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_upload => /admin/uploads/:id(.:format)
  // function(id, options)
  admin_upload_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"uploads",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_uploads => /admin/uploads(.:format)
  // function(options)
  admin_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_price => /api/price(.:format)
  // function(options)
  api_price_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"price",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_purchase => /api/purchases/:id(.:format)
  // function(id, options)
  api_purchase_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_purchases => /api/purchases(.:format)
  // function(options)
  api_purchases_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"purchases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_store_address => /api/stores/:store_id/addresses/:address_id(.:format)
  // function(store_id, address_id, options)
  api_store_address_path: Utils.route([["store_id", true], ["address_id", true], ["format", false]], {}, [2, [7, "/", false], [2, [6, "api", false], [2, [7, "/", false], [2, [6, "stores", false], [2, [7, "/", false], [2, [3, "store_id", false], [2, [7, "/", false], [2, [6, "addresses", false], [2, [7, "/", false], [2, [3, "address_id", false], [1, [2, [8, ".", false], [3, "format", false]], false]]]]]]]]]]]),
// api_store_addresses => /api/stores/:store_id/addresses(.:format)
  // function(store_id, options)
  api_store_addresses_path: Utils.route([["store_id", true], ["format", false]], {}, [2, [7, "/", false], [2, [6, "api", false], [2, [7, "/", false], [2, [6, "stores", false], [2, [7, "/", false], [2, [3, "store_id", false], [2, [7, "/", false], [2, [6, "addresses", false], [1, [2, [8, ".", false], [3, "format", false]], false]]]]]]]]]),
// api_store => /api/stores/:id(.:format)
  // function(id, options)
  api_store_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_store_campaign => /api/stores/:store_id/campaigns/:id(.:format)
  // function(store_id, id, options)
  api_store_campaign_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_store_cart => /api/stores/:store_id/cart(.:format)
  // function(store_id, options)
  api_store_cart_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"cart",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_store_product => /api/stores/:store_id/products/:id(.:format)
  // function(store_id, id, options)
  api_store_product_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_store_products => /api/stores/:store_id/products(.:format)
  // function(store_id, options)
  api_store_products_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_store_purchases => /api/stores/:store_id/purchases(.:format)
  // function(store_id, options)
  api_store_purchases_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_store_settings => /api/stores/:store_id/settings(.:format)
  // function(store_id, options)
  api_store_settings_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_stores => /api/stores(.:format)
  // function(options)
  api_stores_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// authorization_store_purchase => /:store_id/purchases/:id/authorization(.:format)
  // function(store_id, id, options)
  authorization_store_purchase_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"authorization",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// build_products_api_stores => /api/stores/build_products(.:format)
  // function(options)
  build_products_api_stores_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[6,"build_products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// buy_now_store_cart => /:store_id/cart/buy_now(.:format)
  // function(store_id, options)
  buy_now_store_cart_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"cart",false],[2,[7,"/",false],[2,[6,"buy_now",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_all_store_manage_campaign => /:store_id/manage/campaigns/:id/cancel_all(.:format)
  // function(store_id, id, options)
  cancel_all_store_manage_campaign_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_api_store_campaign => /api/stores/:store_id/campaigns/:id/cancel(.:format)
  // function(store_id, id, options)
  cancel_api_store_campaign_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// cancel_api_store_purchases => /api/stores/:store_id/purchases/cancel(.:format)
  // function(store_id, options)
  cancel_api_store_purchases_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_store_manage_purchase => /:store_id/manage/purchases/:id/cancel(.:format)
  // function(store_id, id, options)
  cancel_store_manage_purchase_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_store_manage_purchase_item => /:store_id/manage/purchase_items/:id/cancel(.:format)
  // function(store_id, id, options)
  cancel_store_manage_purchase_item_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"purchase_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_store_purchase => /:store_id/purchases/:id/cancel(.:format)
  // function(store_id, id, options)
  cancel_store_purchase_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// confirm_order_api_store_campaigns => /api/stores/:store_id/campaigns/confirm_order/:id(.:format)
  // function(store_id, id, options)
  confirm_order_api_store_campaigns_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[6,"confirm_order",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// contact_store_purchases => /:store_id/purchases/contact(.:format)
  // function(store_id, options)
  contact_store_purchases_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[6,"contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// current_user_api_users => /api/users/current_user(.:format)
  // function(options)
  current_user_api_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"current_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// data_api_store_analytics => /api/stores/:store_id/analytics/data(.:format)
  // function(store_id, options)
  data_api_store_analytics_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"analytics",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// data_store_admin_analytics => /:store_id/admin/analytics/data(.:format)
  // function(store_id, options)
  data_store_admin_analytics_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"analytics",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_store_access_code => /:store_id/access_code/destroy(.:format)
  // function(store_id, options)
  destroy_store_access_code_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"access_code",false],[2,[7,"/",false],[2,[6,"destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dismiss_announcement => /announcements/:id/dismiss(.:format)
  // function(id, options)
  dismiss_announcement_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"announcements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dismiss",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_admin_upload => /admin/uploads/:id/edit(.:format)
  // function(id, options)
  edit_admin_upload_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"uploads",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_store_cart => /api/stores/:store_id/cart/edit(.:format)
  // function(store_id, options)
  edit_api_store_cart_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"cart",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_legacy_admin_announcement => /legacy_admin/announcements/:id/edit(.:format)
  // function(id, options)
  edit_legacy_admin_announcement_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"announcements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_legacy_admin_store => /legacy_admin/stores/:id/edit(.:format)
  // function(id, options)
  edit_legacy_admin_store_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_legacy_admin_user => /legacy_admin/users/:id/edit(.:format)
  // function(id, options)
  edit_legacy_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_store_manage_audited_audit => /:store_id/manage/audited/audits/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_audited_audit_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"audited",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_store_manage_bundle => /:store_id/manage/bundles/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_bundle_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_campaign => /:store_id/manage/campaigns/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_campaign_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_fulfillment => /:store_id/manage/fulfillments/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_fulfillment_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"fulfillments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_owner_detail => /:store_id/manage/owner_details/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_owner_detail_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"owner_details",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_payee_detail => /:store_id/manage/payee_details/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_payee_detail_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"payee_details",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_product_collection => /:store_id/manage/product_collections/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_product_collection_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"product_collections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_purchase => /:store_id/manage/purchases/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_purchase_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_shipping_address => /:store_id/manage/shipping_addresses/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_shipping_address_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"shipping_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_size => /:store_id/manage/sizes/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_size_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"sizes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_store_address => /:store_id/manage/store_addresses/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_store_address_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_store_config => /:store_id/manage/store_configs/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_store_config_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_store_customization => /:store_id/manage/store_customizations/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_store_customization_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_customizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_store_product => /:store_id/manage/store_products/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_store_product_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_store_product_config => /:store_id/manage/store_product_configs/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_store_product_config_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_product_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_store_product_fulfillment_config => /:store_id/manage/store_product_fulfillment_configs/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_store_product_fulfillment_config_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_product_fulfillment_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_store_product_product_image => /:store_id/manage/store_products/:store_product_id/product_images/:id/edit(.:format)
  // function(store_id, store_product_id, id, options)
  edit_store_manage_store_product_product_image_path: Utils.route([["store_id",true],["store_product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_products",false],[2,[7,"/",false],[2,[3,"store_product_id",false],[2,[7,"/",false],[2,[6,"product_images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_store_manage_store_role => /:store_id/manage/store_roles/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_store_role_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_roles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_variant => /:store_id/manage/variants/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_variant_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"variants",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_store_manage_wave => /:store_id/manage/waves/:id/edit(.:format)
  // function(store_id, id, options)
  edit_store_manage_wave_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"waves",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// finalize_store_purchase => /:store_id/purchases/:id/finalize(.:format)
  // function(store_id, id, options)
  finalize_store_purchase_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"finalize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fulfill_all_store_manage_campaign => /:store_id/manage/campaigns/:id/fulfill_all(.:format)
  // function(store_id, id, options)
  fulfill_all_store_manage_campaign_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"fulfill_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// fulfill_api_store_campaign => /api/stores/:store_id/campaigns/:id/fulfill(.:format)
  // function(store_id, id, options)
  fulfill_api_store_campaign_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"fulfill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// fulfill_store_manage_campaign => /:store_id/manage/campaigns/:id/fulfill(.:format)
  // function(store_id, id, options)
  fulfill_store_manage_campaign_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"fulfill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// fulfill_store_manage_store_product => /:store_id/manage/store_products/:id/fulfill(.:format)
  // function(store_id, id, options)
  fulfill_store_manage_store_product_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"fulfill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// fulfill_store_manage_wave => /:store_id/manage/waves/:id/fulfill(.:format)
  // function(store_id, id, options)
  fulfill_store_manage_wave_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"waves",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"fulfill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// fulfill_store_manage_wave_batches => /:store_id/manage/wave_batches/fulfill(.:format)
  // function(store_id, options)
  fulfill_store_manage_wave_batches_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"wave_batches",false],[2,[7,"/",false],[2,[6,"fulfill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// legacy_admin_announcement => /legacy_admin/announcements/:id(.:format)
  // function(id, options)
  legacy_admin_announcement_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"announcements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// legacy_admin_announcements => /legacy_admin/announcements(.:format)
  // function(options)
  legacy_admin_announcements_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"announcements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// legacy_admin_audited_audit => /legacy_admin/audited/audits/:id(.:format)
  // function(id, options)
  legacy_admin_audited_audit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"audited",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// legacy_admin_store => /legacy_admin/stores/:id(.:format)
  // function(id, options)
  legacy_admin_store_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// legacy_admin_user => /legacy_admin/users/:id(.:format)
  // function(id, options)
  legacy_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// legacy_admin_users => /legacy_admin/users(.:format)
  // function(options)
  legacy_admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_store => /admin/stores/new(.:format)
  // function(options)
  new_admin_store_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_upload => /admin/uploads/new(.:format)
  // function(options)
  new_admin_upload_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"uploads",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_store_cart => /api/stores/:store_id/cart/new(.:format)
  // function(store_id, options)
  new_api_store_cart_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"cart",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_legacy_admin_announcement => /legacy_admin/announcements/new(.:format)
  // function(options)
  new_legacy_admin_announcement_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"announcements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_legacy_admin_user => /legacy_admin/users/new(.:format)
  // function(options)
  new_legacy_admin_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"legacy_admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_store => /stores/new(.:format)
  // function(options)
  new_store_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_store_access_code => /:store_id/access_code/new(.:format)
  // function(store_id, options)
  new_store_access_code_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"access_code",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_store_admin_products => /:store_id/admin/products/new(.:format)
  // function(store_id, options)
  new_store_admin_products_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_bundle => /:store_id/manage/bundles/new(.:format)
  // function(store_id, options)
  new_store_manage_bundle_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_campaign => /:store_id/manage/campaigns/new(.:format)
  // function(store_id, options)
  new_store_manage_campaign_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_product_collection => /:store_id/manage/product_collections/new(.:format)
  // function(store_id, options)
  new_store_manage_product_collection_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"product_collections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_purchase_purchase_adjustment => /:store_id/manage/purchases/:purchase_id/purchase_adjustments/new(.:format)
  // function(store_id, purchase_id, options)
  new_store_manage_purchase_purchase_adjustment_path: Utils.route([["store_id",true],["purchase_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"purchase_id",false],[2,[7,"/",false],[2,[6,"purchase_adjustments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_store_manage_shipping_address => /:store_id/manage/shipping_addresses/new(.:format)
  // function(store_id, options)
  new_store_manage_shipping_address_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"shipping_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_size => /:store_id/manage/sizes/new(.:format)
  // function(store_id, options)
  new_store_manage_size_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"sizes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_store_address => /:store_id/manage/store_addresses/new(.:format)
  // function(store_id, options)
  new_store_manage_store_address_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_store_product => /:store_id/manage/store_products/new(.:format)
  // function(store_id, options)
  new_store_manage_store_product_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_products",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_store_product_product_image => /:store_id/manage/store_products/:store_product_id/product_images/new(.:format)
  // function(store_id, store_product_id, options)
  new_store_manage_store_product_product_image_path: Utils.route([["store_id",true],["store_product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_products",false],[2,[7,"/",false],[2,[3,"store_product_id",false],[2,[7,"/",false],[2,[6,"product_images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_store_manage_store_role => /:store_id/manage/store_roles/new(.:format)
  // function(store_id, options)
  new_store_manage_store_role_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_roles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_variant => /:store_id/manage/variants/new(.:format)
  // function(store_id, options)
  new_store_manage_variant_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"variants",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_store_manage_wave => /:store_id/manage/waves/new(.:format)
  // function(store_id, options)
  new_store_manage_wave_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"waves",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// packing_slip_store_manage_shipment => /:store_id/manage/shipments/:id/packing_slip(.:format)
  // function(store_id, id, options)
  packing_slip_store_manage_shipment_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"packing_slip",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// pause_store_manage_wave_batches => /:store_id/manage/wave_batches/pause(.:format)
  // function(store_id, options)
  pause_store_manage_wave_batches_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"wave_batches",false],[2,[7,"/",false],[2,[6,"pause",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// products_api_store_analytics => /api/stores/:store_id/analytics/products(.:format)
  // function(store_id, options)
  products_api_store_analytics_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"analytics",false],[2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// products_store_admin_analytics => /:store_id/admin/analytics/products(.:format)
  // function(store_id, options)
  products_store_admin_analytics_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"analytics",false],[2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// reorder_api_store_products => /api/stores/:store_id/products/reorder(.:format)
  // function(store_id, options)
  reorder_api_store_products_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"reorder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// replace_api_store_campaign => /api/stores/:store_id/campaigns/:id/replace(.:format)
  // function(store_id, id, options)
  replace_api_store_campaign_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stores",false],[2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"replace",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// rswag_api => /api-docs
  // function(options)
  rswag_api_path: Utils.route([], {}, [2,[7,"/",false],[6,"api-docs",false]]),
// rswag_ui => /api-docs
  // function(options)
  rswag_ui_path: Utils.route([], {}, [2,[7,"/",false],[6,"api-docs",false]]),
// search_store_purchases => /:store_id/purchases/search(.:format)
  // function(store_id, options)
  search_store_purchases_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// shipping_store_purchases => /:store_id/purchases/shipping(.:format)
  // function(store_id, options)
  shipping_store_purchases_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[6,"shipping",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sidekiq => /sidekiq(.:format)
  // function(options)
  sidekiq_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sidekiq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// signalman => /signalman
  // function(options)
  signalman_path: Utils.route([], {}, [2,[7,"/",false],[6,"signalman",false]]),
// signalman.root => /signalman/
  // function(options)
  signalman_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"signalman",false]],[7,"/",false]]),
// signalman.features => /signalman/features(.:format)
  // function(options)
  signalman_features_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"signalman",false]],[7,"/",false]],[2,[6,"features",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// signalman.feature => /signalman/features/:id(.:format)
  // function(id, options)
  signalman_feature_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"signalman",false]],[7,"/",false]],[2,[6,"features",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// signalman.redirect => /signalman/redirects/:id(.:format)
  // function(id, options)
  signalman_redirect_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"signalman",false]],[7,"/",false]],[2,[6,"redirects",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// store_access_code => /:store_id/access_code(.:format)
  // function(store_id, options)
  store_access_code_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"access_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// store_admin_analytics => /:store_id/admin/analytics(.:format)
  // function(store_id, options)
  store_admin_analytics_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"analytics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_admin_root => /:store_id/admin(.:format)
  // function(store_id, options)
  store_admin_root_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// store_admin_statement => /:store_id/admin/statements/:id(.:format)
  // function(store_id, id, options)
  store_admin_statement_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_admin_statements => /:store_id/admin/statements(.:format)
  // function(store_id, options)
  store_admin_statements_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_bundle => /:store_id/bundles/:id(.:format)
  // function(store_id, id, options)
  store_bundle_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_cart => /:store_id/cart(.:format)
  // function(store_id, options)
  store_cart_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"cart",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// store_manage_audited_audit => /:store_id/manage/audited/audits/:id(.:format)
  // function(store_id, id, options)
  store_manage_audited_audit_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"audited",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// store_manage_audited_audits => /:store_id/manage/audited/audits(.:format)
  // function(store_id, options)
  store_manage_audited_audits_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"audited",false],[2,[7,"/",false],[2,[6,"audits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_bundle => /:store_id/manage/bundles/:id(.:format)
  // function(store_id, id, options)
  store_manage_bundle_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_bundles => /:store_id/manage/bundles(.:format)
  // function(store_id, options)
  store_manage_bundles_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"bundles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_campaign => /:store_id/manage/campaigns/:id(.:format)
  // function(store_id, id, options)
  store_manage_campaign_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_campaigns => /:store_id/manage/campaigns(.:format)
  // function(store_id, options)
  store_manage_campaigns_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"campaigns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_fulfillment => /:store_id/manage/fulfillments/:id(.:format)
  // function(store_id, id, options)
  store_manage_fulfillment_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"fulfillments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_fulfillments => /:store_id/manage/fulfillments(.:format)
  // function(store_id, options)
  store_manage_fulfillments_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"fulfillments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_location_dashboard => /:store_id/manage/location-dashboard(.:format)
  // function(store_id, options)
  store_manage_location_dashboard_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"location-dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_location_dashboard_download => /:store_id/manage/location-dashboard/download(.:format)
  // function(store_id, options)
  store_manage_location_dashboard_download_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"location-dashboard",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_location_dashboard_search => /:store_id/manage/location-dashboard/search(.:format)
  // function(store_id, options)
  store_manage_location_dashboard_search_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"location-dashboard",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_owner_detail => /:store_id/manage/owner_details/:id(.:format)
  // function(store_id, id, options)
  store_manage_owner_detail_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"owner_details",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_payee_detail => /:store_id/manage/payee_details/:id(.:format)
  // function(store_id, id, options)
  store_manage_payee_detail_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"payee_details",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_product_collection => /:store_id/manage/product_collections/:id(.:format)
  // function(store_id, id, options)
  store_manage_product_collection_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"product_collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_product_collections => /:store_id/manage/product_collections(.:format)
  // function(store_id, options)
  store_manage_product_collections_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"product_collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_purchase => /:store_id/manage/purchases/:id(.:format)
  // function(store_id, id, options)
  store_manage_purchase_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_purchase_item => /:store_id/manage/purchase_items/:id(.:format)
  // function(store_id, id, options)
  store_manage_purchase_item_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"purchase_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_purchase_items => /:store_id/manage/purchase_items(.:format)
  // function(store_id, options)
  store_manage_purchase_items_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"purchase_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_purchase_purchase_adjustments => /:store_id/manage/purchases/:purchase_id/purchase_adjustments(.:format)
  // function(store_id, purchase_id, options)
  store_manage_purchase_purchase_adjustments_path: Utils.route([["store_id",true],["purchase_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"purchase_id",false],[2,[7,"/",false],[2,[6,"purchase_adjustments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// store_manage_purchases => /:store_id/manage/purchases(.:format)
  // function(store_id, options)
  store_manage_purchases_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"purchases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_root => /:store_id/manage(.:format)
  // function(store_id, options)
  store_manage_root_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// store_manage_shipment => /:store_id/manage/shipments/:id(.:format)
  // function(store_id, id, options)
  store_manage_shipment_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_shipments => /:store_id/manage/shipments(.:format)
  // function(store_id, options)
  store_manage_shipments_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"shipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_shipping_address => /:store_id/manage/shipping_addresses/:id(.:format)
  // function(store_id, id, options)
  store_manage_shipping_address_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"shipping_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_shipping_addresses => /:store_id/manage/shipping_addresses(.:format)
  // function(store_id, options)
  store_manage_shipping_addresses_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"shipping_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_size => /:store_id/manage/sizes/:id(.:format)
  // function(store_id, id, options)
  store_manage_size_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"sizes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_sizes => /:store_id/manage/sizes(.:format)
  // function(store_id, options)
  store_manage_sizes_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"sizes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_store_address => /:store_id/manage/store_addresses/:id(.:format)
  // function(store_id, id, options)
  store_manage_store_address_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_store_addresses => /:store_id/manage/store_addresses(.:format)
  // function(store_id, options)
  store_manage_store_addresses_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_store_config => /:store_id/manage/store_configs/:id(.:format)
  // function(store_id, id, options)
  store_manage_store_config_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_store_config_resend_email => /:store_id/manage/store_configs/:store_config_id/resend_email(.:format)
  // function(store_id, store_config_id, options)
  store_manage_store_config_resend_email_path: Utils.route([["store_id",true],["store_config_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_configs",false],[2,[7,"/",false],[2,[3,"store_config_id",false],[2,[7,"/",false],[2,[6,"resend_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// store_manage_store_customization => /:store_id/manage/store_customizations/:id(.:format)
  // function(store_id, id, options)
  store_manage_store_customization_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_customizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_store_product => /:store_id/manage/store_products/:id(.:format)
  // function(store_id, id, options)
  store_manage_store_product_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_store_product_config => /:store_id/manage/store_product_configs/:id(.:format)
  // function(store_id, id, options)
  store_manage_store_product_config_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_product_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_store_product_fulfillment_config => /:store_id/manage/store_product_fulfillment_configs/:id(.:format)
  // function(store_id, id, options)
  store_manage_store_product_fulfillment_config_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_product_fulfillment_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_store_product_product_image => /:store_id/manage/store_products/:store_product_id/product_images/:id(.:format)
  // function(store_id, store_product_id, id, options)
  store_manage_store_product_product_image_path: Utils.route([["store_id",true],["store_product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_products",false],[2,[7,"/",false],[2,[3,"store_product_id",false],[2,[7,"/",false],[2,[6,"product_images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// store_manage_store_product_product_images => /:store_id/manage/store_products/:store_product_id/product_images(.:format)
  // function(store_id, store_product_id, options)
  store_manage_store_product_product_images_path: Utils.route([["store_id",true],["store_product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_products",false],[2,[7,"/",false],[2,[3,"store_product_id",false],[2,[7,"/",false],[2,[6,"product_images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// store_manage_store_products => /:store_id/manage/store_products(.:format)
  // function(store_id, options)
  store_manage_store_products_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_store_role => /:store_id/manage/store_roles/:id(.:format)
  // function(store_id, id, options)
  store_manage_store_role_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_store_roles => /:store_id/manage/store_roles(.:format)
  // function(store_id, options)
  store_manage_store_roles_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"store_roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_variant => /:store_id/manage/variants/:id(.:format)
  // function(store_id, id, options)
  store_manage_variant_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"variants",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_variants => /:store_id/manage/variants(.:format)
  // function(store_id, options)
  store_manage_variants_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"variants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_wave => /:store_id/manage/waves/:id(.:format)
  // function(store_id, id, options)
  store_manage_wave_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"waves",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// store_manage_wave_batches => /:store_id/manage/wave_batches(.:format)
  // function(store_id, options)
  store_manage_wave_batches_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"wave_batches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_manage_waves => /:store_id/manage/waves(.:format)
  // function(store_id, options)
  store_manage_waves_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"waves",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_new_purchase => /:store_id/checkout(.:format)
  // function(store_id, options)
  store_new_purchase_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"checkout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// store_product => /:store_id/products/:id(.:format)
  // function(store_id, id, options)
  store_product_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_products => /:store_id(.:format)
  // function(store_id, options)
  store_products_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// store_purchase => /:store_id/purchases/:id(.:format)
  // function(store_id, id, options)
  store_purchase_path: Utils.route([["store_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// store_purchases => /:store_id/purchases(.:format)
  // function(store_id, options)
  store_purchases_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_current_store_manage_wave_batches => /:store_id/manage/wave_batches/update_current(.:format)
  // function(store_id, options)
  update_current_store_manage_wave_batches_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"manage",false],[2,[7,"/",false],[2,[6,"wave_batches",false],[2,[7,"/",false],[2,[6,"update_current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_customink_omniauth_authorize => /users/auth/customink(.:format)
  // function(options)
  user_customink_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"customink",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_customink_omniauth_callback => /users/auth/customink/callback(.:format)
  // function(options)
  user_customink_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"customink",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// voucher_store_purchases => /:store_id/purchases/voucher(.:format)
  // function(store_id, options)
  voucher_store_purchases_path: Utils.route([["store_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"store_id",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[6,"voucher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// webhooks_ofs_update => /webhooks/ofs/update(.:format)
  // function(options)
  webhooks_ofs_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"ofs",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return Utils.make();
    });
  } else {
    module.exports = Utils.make();
  }

}).call(this);
