import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { change as changeReduxFormValue } from 'redux-form';

import { Button } from 'components/elements';
import Voucher from './Purchase/Voucher';
import Routes from 'lib/routes';
import storeId from 'utils/storeId';
import PurchaseService from 'services/purchase';
import { changePurchase } from 'actions/purchase';
import { paymentSection } from 'constants/PurchaseFormConstants';
import SplitButton from 'components/SplitButton';
const { voucher } = PurchaseService;

const _CartSummaryContext = 'CartSummary';

class OrderSummary extends Component {
  proceedToCheckout() {
    window.location.pathname = Routes.store_new_purchase_path(storeId());
  }

  proceedToCheckoutWithQuery(value = 0) {
    window.location.href = `${Routes.store_new_purchase_path(
      storeId()
    )}?use_legacy=${value}`;
  }

  removeVoucher = async e => {
    e.preventDefault();
    const { dispatch, purchaseForm, shippingForm } = this.props;
    purchaseForm.voucher.code = null;
    const values = { purchase: purchaseForm, shipping: shippingForm };
    const { purchase } = await voucher(values, dispatch);
    if (purchase) {
      dispatch(changePurchase(purchase));
    }
  };

  addVoucher = async values => {
    const { dispatch } = this.props;
    const { purchase } = await voucher(values, dispatch);

    if (purchase) {
      dispatch(changePurchase(purchase));
      // We must deselect the payment skipped option any time a voucher is applied
      dispatch(changeReduxFormValue('purchase', 'payment.skipped', false));
    }
  };

  renderCheckoutButton = () => {
    const { context, checkoutDisabled } = this.props;
    if (context != _CartSummaryContext) {
      return;
    }
    return this.isInternal() && this.props.isCCOEligible ? (
      <div className="stores-OrderSummary-checkoutBtn">
        <SplitButton
          onClick={this.proceedToCheckoutWithQuery}
          options={[
            {
              display: 'Proceed to Checkout',
              value: 0
            },
            { display: 'Proceed to Checkout (old)', value: 1 }
          ]}
          disabled={checkoutDisabled}
          className="sb-Btn--block sb-Btn--large"
        />
      </div>
    ) : (
      <div className="stores-OrderSummary-checkoutBtn">
        <Button
          onClick={() => {
            if (this.props.isCCOEligible) {
              this.proceedToCheckoutWithQuery(0);
            } else {
              this.proceedToCheckout();
            }
          }}
          disabled={checkoutDisabled}
          className="sb-Btn--block sb-Btn--large"
        >
          Proceed to Checkout
        </Button>
      </div>
    );
  };

  isInternal = () => {
    const { user } = this.props;
    return user?.role === 'admin' || user?.role === 'internal';
  };

  render() {
    const {
      context,
      checkoutDisabled,
      discountAmount,
      hasValidVoucherCode,
      internationalDisclaimer,
      orderSummaryDiscountLabel,
      orderSummaryTotalLabel,
      paymentMethod,
      quantity,
      section,
      serviceCharges,
      serviceDiscounts,
      shipping,
      subtotal,
      tax,
      total,
      voucherCode,
      voucherCodeRemovable,
      vouchersEnabled
    } = this.props;

    const totalLabel =
      context === 'CartSummary'
        ? 'Estimated Total'
        : orderSummaryTotalLabel || 'Order Total';
    const discountLabel = orderSummaryDiscountLabel || 'Discount';

    return (
      <div className="sb-Panel">
        <div className="sb-Panel-productContainer">
          <p>
            <strong>Order Summary</strong>
          </p>
          <p className="stores-OrderSummary-lineItem">
            Number of Items:{' '}
            <span className="stores-OrderSummary-lineItemValue">
              {quantity}
            </span>
          </p>
          <p className="stores-OrderSummary-lineItem">
            Subtotal:{' '}
            <span className="stores-OrderSummary-lineItemValue">
              {subtotal}
            </span>
          </p>
          {serviceCharges && serviceCharges.length > 0 && (
            <p className="stores-OrderSummary-lineItem">
              {`Service Charge${serviceCharges.length > 1 ? 's' : ''}:`}
              {serviceCharges.map(serviceCharge => (
                <>
                  <span className="stores-OrderSummary-lineItemValue">
                    {serviceCharge}
                  </span>
                  <br />
                </>
              ))}
            </p>
          )}
          {serviceDiscounts && serviceDiscounts.length > 0 && (
            <p className="stores-OrderSummary-lineItem">
              {`Service Discount${serviceDiscounts.length > 1 ? 's' : ''}:`}
              {serviceDiscounts.map(serviceDiscount => (
                <>
                  <span className="stores-OrderSummary-lineItemValue">
                    {serviceDiscount}
                  </span>
                  <br />
                </>
              ))}
            </p>
          )}
          {shipping && (
            <p className="stores-OrderSummary-lineItem">
              Shipping & Handling:{' '}
              <span className="stores-OrderSummary-lineItemValue">
                {section === 2 || total !== '--' ? shipping : '--'}
              </span>
            </p>
          )}
          {tax && (
            <p className="stores-OrderSummary-lineItem">
              Tax:
              <span className="stores-OrderSummary-lineItemValue">{tax}</span>
            </p>
          )}
          {vouchersEnabled &&
            section === paymentSection &&
            context === 'Purchase/Summary' && (
              <Voucher
                handleRemove={this.removeVoucher}
                hasValidVoucherCode={hasValidVoucherCode}
                onSubmit={this.addVoucher}
                removable={voucherCodeRemovable}
              />
            )}
          {vouchersEnabled &&
            context !== 'CartSummary' &&
            hasValidVoucherCode &&
            discountAmount &&
            discountAmount !== '$0.00' && (
              <div>
                <p className="stores-OrderSummary-lineItem">
                  {discountLabel}:
                  <span className="stores-OrderSummary-lineItemValue stores-OrderSummary-lineItemValue--credit">
                    ({discountAmount})
                  </span>
                </p>
              </div>
            )}
          <div className="stores-OrderSummary-total">
            <strong>{totalLabel}</strong>:{' '}
            <span className="stores-OrderSummary-lineItemValue">
              <strong>{total}</strong>
            </span>
            {context === 'CartSummary' && (
              <small>
                <br />
                (Tax & shipping to be included)
              </small>
            )}
            {context === 'PurchaseConfirmationPageOrderSummary' && (
              <small>
                <br />
                Paid via{' '}
                {paymentMethod === 'Voucher'
                  ? `Voucher ${voucherCode}`
                  : paymentMethod}
              </small>
            )}
            {internationalDisclaimer && (
              <div className="sb-Alert stores-OrderSummary-disclaimer">
                <div className="sb-Alert-icon">
                  <i className="sb-Icon--warning"></i>
                </div>
                <div className="sb-Alert-message">
                  <small>{internationalDisclaimer}</small>
                </div>
              </div>
            )}
          </div>
          {this.renderCheckoutButton()}
        </div>
      </div>
    );
  }
}

OrderSummary.propTypes = {
  checkoutDisabled: PropTypes.bool,
  context: PropTypes.string,
  discountAmount: PropTypes.string,
  hasValidVoucherCode: PropTypes.bool,
  internationalDisclaimer: PropTypes.string,
  quantity: PropTypes.number,
  shipping: PropTypes.string,
  serviceCharges: PropTypes.array,
  serviceDiscounts: PropTypes.array,
  subtotal: PropTypes.string,
  tax: PropTypes.string,
  total: PropTypes.string,
  voucherCode: PropTypes.string,
  vouchersEnabled: PropTypes.bool
};

export default OrderSummary;
