import Rails from 'rails-ujs';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PurchaseConfirmationPropTypes } from 'utils/ReusablePropTypes';
import RecommendedProductsContainer from 'components/RecommendedProductsContainer';
import Summary from 'components/Purchase/PurchaseConfirmationPageSummary';
import Shipments from 'components/Purchase/PurchaseConfirmationPageShipments';

Rails.start();

const PurchaseConfirmationPage = ({ purchase }) => {
  const handleCancelOrder = e => {
    console.log('cancel the order');
  };

  return (
    <div className="stores-PurchaseConfirmation">
      <Summary handleCancelOrder={handleCancelOrder} />
      <Shipments />
      <RecommendedProductsContainer products={purchase.recommendedPromotions} />
    </div>
  );
};

PurchaseConfirmationPage.propTypes = {
  purchase: PropTypes.shape(PurchaseConfirmationPropTypes)
};

const mapStateToProps = ({ purchaseConfirmation }) => ({
  purchase: purchaseConfirmation
});

export default connect(mapStateToProps)(PurchaseConfirmationPage);
