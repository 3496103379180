import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormGroup } from 'components/Purchase/FormGroup';
import { connect } from 'react-redux';
import updatePurchase from 'services/updatePurchase';
import { openModal, closeModal } from 'actions/modal';
import { purchaseSummaryMessage } from 'actions/purchaseSummary';
import { updatePurchaseConfirmation } from 'actions/purchaseConfirmation';
import { metrics } from 'utils/metrics';

const PurchaseUpdateShippingAddress = ({
  dispatch,
  handleSubmit,
  close,
  admin,
  countries,
  states,
  submitting,
  error,
  purchaseSecureId,
  addressId
}) => {
  const buildFormData = values => {
    return {
      id: purchaseSecureId,
      purchase: {
        shipping_address: {
          id: addressId,
          first_name: values.first_name,
          last_name: values.last_name,
          address_1: values.address_1,
          address_2: values.address_2,
          city: values.city,
          country: values.country.toUpperCase(),
          state: values.state,
          postal_code: values.postal_code
        }
      }
    };
  };

  const handleClose = () => {
    metrics.pageViewTag('/os/orderstatus/statuspage/update/changeaddress/close');
    dispatch(closeModal());
    dispatch(openModal('PurchaseUpdateShippingAddress', {}));
  };

  const handleShippingAddressWithValidation = async values => {
    let data = buildFormData(values);

    try {
      const {
        purchase,
        purchaseConfirmation,
        valid_address,
        suggested_address,
        errors
      } = await updatePurchase(dispatch, data);

      if (errors) {
        throw new SubmissionError(response.errors.purchase);
      } else if (!valid_address) {
        return openAddressConfirmModal(values);
      } else if (suggested_address) {
        // Add user name to the suggested address
        suggested_address.first_name = values.first_name;
        suggested_address.last_name = values.last_name;

        // Compare suggestion with current address to see if it has any differences
        const addressFieldsToCheck = [
          'address_1',
          'address_2',
          'city',
          'state',
          'postal_code',
          'country'
        ];

        const isSuggestionDifferent = addressFieldsToCheck.some(
          key => suggested_address[key] != values[key]
        );

        // If so, let the user confirm the suggested address
        if (isSuggestionDifferent) {
          return openAddressConfirmModal(values, suggested_address);
        }
      }

      dispatch(closeModal());
      dispatch(purchaseSummaryMessage('Your order has been updated'));
      dispatch(updatePurchaseConfirmation(purchaseConfirmation));
      metrics.pageViewTag('/os/orderstatus/statuspage/update/changeaddress/success');
    } catch (e) {
      metrics.pageViewTag('/os/orderstatus/statuspage/update/changeaddress/error');
      if (e instanceof SubmissionError) {
        throw e;
      }
      throw new SubmissionError({
        _error: 'Sorry. Updating your shipping address has failed.'
      });
    }
  };

  const handleShippingAddress = async values => {
    let data = buildFormData(values);
    data.skip_validation = true;

    const { purchase, purchaseConfirmation, errors } = await updatePurchase(
      dispatch,
      data
    );

    dispatch(closeModal());
    dispatch(purchaseSummaryMessage('Your order has been updated'));
    dispatch(updatePurchaseConfirmation(purchaseConfirmation));
  };

  const openAddressConfirmModal = (values, suggestedAddress) => {
    dispatch(
      openModal('ConfirmAddress', {
        maxWidth: 650,
        static: true,
        props: {
          suggestedAddress,
          currentAddress: values,
          onSubmit: handleShippingAddress,
          onClose: handleClose
        }
      })
    );
  };

  return (
    <>
      <div className="stores-Modal-header">
        <h1>Change Your Shipping Information</h1>
        <div>
          You can only update your shipping address online to a location that
          does not incur new shipping charges. You can also call (855) 374 4687
          for more options.
        </div>
      </div>

      <form
        className="sb-Form"
        onSubmit={handleSubmit(handleShippingAddressWithValidation)}
      >
        <div className="stores-Modal-content">
          <div className="sb-Form-group sb-Form--inline--flex">
            <Field
              name="first_name"
              type="text"
              component={FormGroup}
              label="First Name"
              validate={required()}
              className="sb-Form-group--mr"
              autoComplete="given-name"
            />
            <Field
              name="last_name"
              type="text"
              component={FormGroup}
              label="Last Name"
              validate={required()}
              autoComplete="family-name"
            />
          </div>

          <Field
            name="address_1"
            type="text"
            component={FormGroup}
            label="Address Line 1"
            validate={required()}
            className="sb-Form-group"
            autoComplete="address-line-1"
          />

          <Field
            name="address_2"
            type="text"
            component={FormGroup}
            label="Address Line 2"
            autoComplete="address-line-2"
          />

          <div className="sb-Form-group sb-Form--inline--flex">
            <Field
              name="city"
              type="text"
              component={FormGroup}
              label="City"
              validate={required()}
              className="sb-Form-group--mr"
              autoComplete="city"
              disabled={!admin}
            />
            <Field
              name="postal_code"
              type="text"
              component={FormGroup}
              label="Zip code"
              validate={required()}
              autoComplete="zip-code"
              disabled={!admin}
            />
          </div>
          <div className="sb-Form-group sb-Form--inline--flex">
            <Field
              name="country"
              type="select"
              component={FormGroup}
              label="Country"
              validate={required()}
              className="sb-Form-group--mr"
              autoComplete="country"
              disabled={!admin}
              options={countries}
              optionLabel="name"
              optionValue="code"
            />
            <Field
              name="state"
              type="select"
              component={FormGroup}
              label="State"
              validate={required()}
              autoComplete="state"
              disabled={!admin}
              options={states}
              optionLabel="name"
              optionValue="code"
            />
          </div>
          {error && (
            <div className="sb-Alert sb-Alert--error">
              <p>{error}</p>
            </div>
          )}
        </div>

        <div className="stores-Modal-footer added-Product-modal-actions">
          <button
            type="submit"
            disabled={submitting}
            className="sb-Btn sb-Btn--primary sb-Btn--responsive"
          >
            Update Shipping
          </button>
          <button
            className="sb-Btn sb-Btn--secondary sb-Btn--responsive sb-Btn--mr"
            disabled={submitting}
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

PurchaseUpdateShippingAddress.propTypes = {
  admin: PropTypes.bool.isRequired
};

const PurchaseUpdateShippingAddressForm = reduxForm({
  form: 'shippingAddress',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnBlur: true,
  touchOnChange: true
})(PurchaseUpdateShippingAddress);

const getStates = state => {
  if (
    state &&
    state.form &&
    state.form.shippingAddress &&
    state.form.shippingAddress.values &&
    state.form.shippingAddress.values.country
  ) {
    const country = state.form.shippingAddress.values.country;
    for (let i = 0; i < state.shippingCountries.length; i++) {
      if (state.shippingCountries[i].code === country) {
        return state.shippingCountries[i].states;
      }
    }
  }

  return [];
};

const mapStateToProps = state => ({
  initialValues: {
    first_name: state.purchaseConfirmation.firstName,
    last_name: state.purchaseConfirmation.lastName,
    address_1: state.purchaseConfirmation.shippingAddress.address1,
    address_2: state.purchaseConfirmation.shippingAddress.address2,
    postal_code: state.purchaseConfirmation.shippingAddress.postalCode,
    ...state.purchaseConfirmation.shippingAddress
  },
  admin: state.user.admin,
  countries: state.shippingCountries,
  states: getStates(state),
  purchaseSecureId: state.purchase.secureId,
  addressId: state.purchaseConfirmation.shippingAddress.id
});

export default connect(mapStateToProps)(PurchaseUpdateShippingAddressForm);
