import React from 'react';
import { Provider } from 'react-redux';
import Modal from './';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const ConnectedModal = props => (
  <Provider store={ReactOnRails.getStore('appStore')}>
    <Modal />
  </Provider>
);

export default ConnectedModal;
