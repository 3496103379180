import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import PurchaseService from 'services/purchase';
const { voucher } = PurchaseService;

import OrderSummary from 'components/OrderSummary';

const mapStateToProps = state => {
  const selector = formValueSelector('purchase');
  const purchaseForm = selector(state, 'purchase');
  const shippingForm = selector(state, 'shipping');

  return {
    context: 'Purchase/Summary',
    discountAmount: state.purchase.discountAmount,
    hasValidVoucherCode: state.purchase.hasValidVoucherCode,
    internationalDisclaimer: state.purchase.internationalDisclaimer,
    orderSummaryDiscountLabel: state.purchase.orderSummaryDiscountLabel,
    orderSummaryTotalLabel: state.purchase.orderSummaryTotalLabel,
    quantity: state.purchase.quantity,
    shipping: state.purchase.shipping,
    serviceCharge: state.purchase.service_charge,
    serviceDiscount: state.purchase.service_discount,
    subtotal: state.purchase.subtotal,
    tax: state.purchase.tax,
    total: state.purchase.total,
    section: state.purchaseForm.section,
    voucherCode: state.purchase.voucherCode,
    vouchersEnabled: state.purchase.vouchersEnabled,
    shippingForm,
    purchaseForm
  };
};

export default connect(mapStateToProps)(OrderSummary);
