import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'lib/routes';
import axios from 'axios';
import Clear from 'images/icons/clear.svg';

class SiteAnnouncements extends React.Component {
  constructor(props) {
    super(props);
    this.state = { announcements: props.announcements };
    this.dismissAnnouncement = this.dismissAnnouncement.bind(this);
  }

  dismissAnnouncement(e, announcement) {
    e.preventDefault();
    axios.patch(Routes.dismiss_announcement_path(announcement.id));
    this.setState(state => ({
      announcements: state.announcements.filter(
        item => item.id !== announcement.id
      )
    }));
  }

  render() {
    return (
      <div className="stores-Announcements">
        {this.state.announcements.map(announcement => (
          <div className="sb-Alert sb-Alert--warning" key={announcement.id}>
            <span className="stores-Announcements-message">
              {announcement.message}
            </span>
            <span className="stores-Announcements-dismiss">
              <a
                href="#"
                title="Dismiss"
                onClick={e => this.dismissAnnouncement(e, announcement)}
              >
                <Clear />
              </a>
            </span>
          </div>
        ))}
      </div>
    );
  }
}

SiteAnnouncements.propTypes = {
  announcements: PropTypes.array
};

export default SiteAnnouncements;
