import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import storeId from 'utils/storeId';
import Routes from 'lib/routes';
import CartIcon from 'images/icons/cart.svg';

export const Cart = ({ quantity }) => {
  return (
    <div className="stores-Header-cart">
      <a
        className="stores-Header-cart-link"
        href={Routes.store_cart_path(storeId())}
      >
        <div>
          <CartIcon alt="Cart" className="stores-Header-cart-image" />
        </div>
        {quantity > 0 && (
          <div className="stores-Header-cart-quantity">
            {quantity > 99 ? '99+' : quantity}{' '}
            {quantity === 1 ? 'item' : 'items'}
          </div>
        )}
      </a>
    </div>
  );
};

const mapStateToProps = state => ({
  quantity: state.cart.quantity
});

Cart.propTypes = {
  quantity: PropTypes.number
};

Cart.defaultProps = {
  quantity: 0
};

export default connect(mapStateToProps)(Cart);
