import React from 'react';

const TableTabs = ({ currentTab, onTabChange, tabs }) => {
  const renderTabs = () => {
    return tabs.map(tab => {
      return (
        <li
          value={tab.value}
          key={tab.value}
          className={`admin-Tabs-tab ${
            currentTab === tab.value ? 'active' : ''
          }`}
          onClick={() => onTabChange(tab.value)}
        >
          {tab.name}
        </li>
      );
    });
  };
  return (
    <div className="admin-Tabs">
      <ul className="admin-Tabs-tabList">{renderTabs()}</ul>
    </div>
  );
};

export default props => <TableTabs {...props} />;
