import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import Summary from 'components/Purchase/Summary';
import { Alert, Button } from '../elements';
import PaymentEntry from './PaymentEntry';
import { FormGroup } from './FormGroup';
import SkipPayment from './SkipPayment';

import { getPaymentCapturable, getSkippedSelection } from 'selectors/purchase';

export const Payment = props => {
  const {
    adminBypass,
    editing,
    error,
    handleSubmit,
    onSubmit,
    paymentCapturable,
    purchaseInvoiceDisclaimer,
    skipPayment,
    submitting
  } = props;
  const [submittable, setSubmittable] = useState(false);

  const disableSubmitButton = adminBypass
    ? !skipPayment
    : submitting || (paymentCapturable && !submittable);

  return (
    <React.Fragment>
      {editing && (
        <div className="sb-Util-visible--sm">
          <Summary />
        </div>
      )}
      <form
        style={{ display: editing ? 'block' : 'none' }}
        className="sb-Form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SkipPayment />

        {error && (
          <Alert
            type="error"
            title="There was an error placing your order"
            text={error}
          />
        )}
        <PaymentEntry
          disabled={!paymentCapturable}
          setSubmittable={setSubmittable}
        />
        {purchaseInvoiceDisclaimer && (
          <p
            className="stores-PurchaseDisclaimer"
            dangerouslySetInnerHTML={purchaseInvoiceDisclaimer}
          />
        )}
        <div className="stores-PurchaseForm-actions sb-Form-group sb-Form-group--buttons">
          <Button
            htmlType="submit"
            disabled={disableSubmitButton}
            className="sb-Btn--large"
          >
            Place Your Order
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  adminBypass: state.user.adminBypass,
  paymentCapturable: getPaymentCapturable(state),
  purchaseInvoiceDisclaimer: state.purchase.purchaseInvoiceDisclaimer,
  skipPayment: getSkippedSelection(state)
});

Payment.propTypes = {
  adminBypass: PropTypes.bool,
  editing: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  paymentCapturable: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'purchase',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnBlur: false
  })(Payment)
);
