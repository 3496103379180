import React from 'react';
import { connect } from 'react-redux';

import OrderSummary from 'components/OrderSummary';

const mapStateToProps = state => {
  const confirmedPurchase = state.purchase;
  const details = state.purchaseConfirmation;

  return {
    context: 'PurchaseConfirmationPageOrderSummary',
    discountAmount: details.discountAmount,
    hasValidVoucherCode: confirmedPurchase.hasValidVoucherCode,
    internationalDisclaimer: confirmedPurchase.internationalDisclaimer,
    orderSummaryDiscountLabel: state.purchase.orderSummaryDiscountLabel,
    orderSummaryTotalLabel: state.purchase.orderSummaryTotalLabel,
    paymentMethod: details.paymentMethod,
    quantity: confirmedPurchase.quantity,
    serviceCharges: details.serviceCharges,
    serviceDiscounts: details.serviceDiscounts,
    shipping: details.shipping,
    subtotal: details.subtotal,
    tax: details.tax,
    total: details.total,
    voucherCode: confirmedPurchase.voucherCode,
    vouchersEnabled: confirmedPurchase.vouchersEnabled
  };
};

export default connect(mapStateToProps)(OrderSummary);
