export const UPDATE_STATUSES = 'UPDATE_STATUSES';

const initialState = {
  columns: [],
  data: []
};

const itemStatuses = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_STATUSES':
      return action.data;
    default:
      return state;
  }
};

export default itemStatuses;
