import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

const skipPaymentAuthorizedSelector = state => state.payment.skipAuthorized;
const paymentCapturableSelector = state => state.purchase.paymentCapturable;
const containsDonationsSelector = state => state.purchase.containsDonations;
const voucherAppliedSelector = state => state.purchase.hasValidVoucherCode;

export const getSkippedSelection = state => {
  const selector = formValueSelector('purchase');

  return selector(state, 'payment.skipped');
};

// purchases can only be skipped if the current user has a service or admin role,
// the payment is capturable (total > 0),
// it does not contain a donation,
// and there is not a voucher in use.
export const getPaymentSkippable = createSelector(
  skipPaymentAuthorizedSelector,
  paymentCapturableSelector,
  containsDonationsSelector,
  voucherAppliedSelector,
  (authorized, paymentCapturable, containsDonations, voucherApplied) =>
    authorized && !voucherApplied && paymentCapturable && !containsDonations
);

// payment is only capturable if the payment itself is capturable (total > 0)
// and the user has not elected to skip the payment
export const getPaymentCapturable = createSelector(
  paymentCapturableSelector,
  getSkippedSelection,
  (capturable, skipped) => capturable && !skipped
);
