import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const DropDownItem = ({ children, ...props }) => (
  <li {...props}>{children}</li>
);
DropDownItem.propTypes = {
  children: PropTypes.any.isRequired
};

const IconUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const IconDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const DropDown = ({ children, label }) => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(!open);
  };
  const close = () => {
    setOpen(false);
  };
  useEffect(() => {
    document.addEventListener('keydown', close, false);

    return () => {
      document.removeEventListener('keydown', close, false);
    };
  });
  return (
    <>
      <div
        className={`dropdown-PurchaseConfirmationMenu ${open ? ' open' : ''}`}
      >
        <div className="backgdrop" onClick={close} />
        <div className="button" onClick={onClick}>
          <span className="text">{label}</span>
          <span className="icon">{open ? <IconUp /> : <IconDown />}</span>
        </div>
        {open && (
          // The on click here closes the menu as expected when you click on one of the items
          <ul className="menu" onClick={close}>
            {children}
          </ul>
        )}
      </div>
    </>
  );
};

DropDown.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default DropDown;
