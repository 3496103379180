import React from 'react';
import { connect } from 'react-redux';

import OrderSummary from 'components/OrderSummary';

const mapStateToProps = state => ({
  checkoutDisabled: state.cart.error && state.cart.error.orderable === false,
  context: 'CartSummary',
  error: state.cart.error,
  isCCOEligible: state.featureFlags.isCCOEligible,
  orderSummaryDiscountLabel: state.purchase.orderSummaryDiscountLabel,
  orderSummaryTotalLabel: state.purchase.orderSummaryTotalLabel,
  quantity: state.cart.quantity,
  subtotal: state.cart.subtotal,
  total: state.cart.total,
  user: state.user
});

export default connect(mapStateToProps)(OrderSummary);
