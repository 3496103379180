import React from 'react';
import { Provider } from 'react-redux';
import CartPage from './CartPage';

const ConnectedCartPage = props => (
  <Provider store={ReactOnRails.getStore('appStore')}>
    <CartPage />
  </Provider>
);

export default ConnectedCartPage;
