import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFilteredShipments } from 'selectors/shipmentsFilter';
import ShipmentTable from 'components/Purchase/ShipmentTable';
import PurchaseConfirmationMenu from 'components/Purchase/PurchaseConfirmationMenu';
import {isInventory} from '../../selectors/storePresentationConfig'

const shipmentMessage = (index, total) => {
  return `Shipment ${index + 1} of ${total}`;
};

const getDigitalShipment = (shipments, total) => {
  const digitalShipment = shipments.find(i => !i.requiresShipping);
  const buyupItemIndex = digitalShipment?.items?.findIndex(
    item => item.hasBuyup
  );

  if (!digitalShipment || buyupItemIndex === undefined || buyupItemIndex < 0) {
    return digitalShipment;
  }

  if (total === '$0.00') {
    digitalShipment.items.splice(buyupItemIndex, 1);
    if (digitalShipment.items.length <= 0) {
      return undefined;
    }
  } else {
    digitalShipment.items[buyupItemIndex].attributes.Amount = total;
  }
  return digitalShipment;
};

const PurchaseConfirmationPageShipments = ({
  bundles,
  shipments,
  shippingToLocation,
  referenceId,
  cancelable,
  unfulfilled,
  isOrganizerPurchase,
  cancelUrl,
  isInventoryStore,
  total
}) => {
  let digitalShipment = getDigitalShipment(shipments, total);

  const physicalShipments = shipments.filter(i => i.requiresShipping);

  return (
    <section className="stores-PurchaseConfirmationShipments">
      <React.Fragment>
        <div className="stores-PurchaseConfirmationShipmentsWrapper">
          <div className="sb-Wrapper">
            <PurchaseConfirmationMenu
              referenceId={referenceId}
              cancelable={cancelable}
              cancelUrl={cancelUrl}
              unfulfilled={unfulfilled}
              shippingToLocation={shippingToLocation}
              isInventoryStore={isInventoryStore}
            />
            {digitalShipment && (
              <ShipmentTable shipment={digitalShipment} showStatus={false} />
            )}
            {isOrganizerPurchase ? (
              <OrganizerShipments shipments={shipments} bundles={bundles} />
            ) : (
              <Shipments shipments={physicalShipments} bundles={bundles} />
            )}
          </div>
        </div>
      </React.Fragment>
    </section>
  );
};

const OrganizerShipments = ({ shipments, bundles }) => {
  const organizerShipments = shipments.filter(
    i => i.requiresShipping && !i.shippingToIndividual
  );
  const individualShipment = shipments.find(i => i.shippingToIndividual);

  return (
    <>
      {organizerShipments.length > 0 && (
        <>
          <h2>Items shipping to you</h2>
          <Shipments shipments={organizerShipments} bundles={bundles} />
        </>
      )}
      {individualShipment && (
        <>
          <h2>Items shipping to individuals</h2>
          <ShipmentTable shipment={individualShipment} showStatus={false} />
        </>
      )}
    </>
  );
};

const Shipments = ({ shipments, bundles }) => {
  return shipments.map((shipment, index) => (
    <React.Fragment key={index}>
      {bundles.length === 0 && (
        <h4>{shipmentMessage(index, shipments.length)}</h4>
      )}
      <ShipmentTable
        shipment={shipment}
        bundle={bundles.length > 0 ? bundles[0] : undefined}
      />
    </React.Fragment>
  ));
};

PurchaseConfirmationPageShipments.propTypes = {
  active: PropTypes.bool,
  bundles: PropTypes.array,
  shipments: PropTypes.array
};

const mapStateToProps = state => ({
  bundles: state.store.bundles,
  referenceId: state.purchaseConfirmation.referenceId,
  cancelable: state.purchaseConfirmation.cancelable,
  cancelUrl: state.purchaseConfirmation.cancelUrl,
  shippingToLocation: state.purchaseConfirmation.shippingToLocation,
  shipments: getFilteredShipments(state),
  unfulfilled: state.purchaseConfirmation.unfulfilled,
  isOrganizerPurchase: state.purchaseConfirmation.isOrganizerPurchase,
  total: state.purchaseConfirmation.total,
  isInventoryStore: isInventory(state)
});

export default connect(mapStateToProps)(PurchaseConfirmationPageShipments);
