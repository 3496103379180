import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BundlePropTypes,
  BundleProductsPropTypes,
  ImagePropTypes
} from 'utils/ReusablePropTypes';

import Details from 'components/BundleDetails';
import DetailsSmall from 'components/BundleDetailsSmall';

import { getBundleImages } from 'selectors/bundleProducts';

const BundleContainer = ({ bundle, bundleProducts, images }) => {
  return (
    <div className="stores-BundleDetails">
      <div className="stores-ProductDetails sb-Util-hidden--sm">
        <Details {...bundle} {...bundleProducts} images={images} />
      </div>
      <div className="stores-ProductDetails sb-Util-visible--sm">
        <DetailsSmall {...bundle} {...bundleProducts} images={images} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    bundle: state.bundle,
    bundleProducts: state.bundleProducts,
    images: getBundleImages(state)
  };
};

BundleContainer.propTypes = {
  bundle: PropTypes.shape(BundlePropTypes).isRequired,
  bundleProducts: PropTypes.shape(BundleProductsPropTypes).isRequired,
  images: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)).isRequired
};

export default connect(mapStateToProps)(BundleContainer);
