import React from 'react';
import { useTable } from 'react-table';

const ItemStatusTable = ({ itemStatuses }) => {
  const { data } = itemStatuses;

  const preparedData = data.map(row => {
    return {
      purchaseReferenceId: row.purchaseReferenceId,
      ciOrderId: row.ciOrderId,
      purchaseDate: row.purchaseDate,
      itemStatus: {
        status: row.itemStatus,
        statusAt: row.itemStatusAt,
        message: row.itemStatusMessage
      },
      contactInfo: {
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email
      },
      productInfo: {
        productName: row.productName,
        variant: row.variant,
        size: row.size,
        quantity: row.quantity
      }
    };
  });

  const columns = [
    { Header: 'Order #', accessor: 'purchaseReferenceId' },
    {
      Header: () => (
        <abbr title="You can find this number on the package label">
          Package #
        </abbr>
      ),
      accessor: 'ciOrderId',
      Cell: ({ cell: { value } }) => {
        const cellValue = value ? value : 'N/A';

        return <div style={{ textAlign: 'right' }}>{cellValue}</div>;
      }
    },
    { Header: 'Date', accessor: 'purchaseDate' },
    {
      Header: 'Status',
      accessor: 'itemStatus',
      Cell: ({ cell: { value } }) => {
        const statusClass = `--${value.status}`;
        const title = `${value.message} on ${value.statusAt}`;

        return (
          <abbr
            className={`stores-ItemStatusTable-itemStatus${statusClass}`}
            title={title}
          >
            {value.message}
          </abbr>
        );
      }
    },
    {
      Header: 'Name',
      accessor: 'contactInfo',
      Cell: ({ cell: { value } }) => (
        <>
          {value.firstName} {value.lastName}
          <br />
          {value.email}
        </>
      )
    },
    {
      Header: 'Product Name',
      accessor: 'productInfo',
      Cell: ({ cell: { value } }) => (
        <>
          {value.productName}
          <br />
          {value.variant}, {value.size}, (x{value.quantity})
        </>
      )
    }
  ];

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: preparedData
  });

  return (
    <div className="stores-ItemStatusTable">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map(
            (row, i) =>
              prepareRow(row) || (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

// https://github.com/shakacode/react_on_rails/issues/1198
export default props => <ItemStatusTable {...props} />;
