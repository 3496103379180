import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change as changeReduxFormValue } from 'redux-form';

import Contact from './Contact';
import Shipping from './Shipping';
import Payment from './Payment';
import { Provider } from 'react-redux';

import { changePurchase } from 'actions/purchase';
import { updateSection } from 'actions/purchaseForm';
import { openModal } from 'actions/modal';
import PurchaseService from 'services/purchase';
import { getPaymentCapturable } from 'selectors/purchase';
import {
  contactSection,
  shippingSection,
  paymentSection
} from 'constants/PurchaseFormConstants';

const { contact, shipping, voucher, payment } = PurchaseService;

class PurchaseForm extends Component {
  processPayment = async () => {
    const { braintree, paymentCapturable } = this.props;

    if (!braintree || !paymentCapturable) {
      return null;
    }

    return braintree.requestPaymentMethod();
  };

  submitContact = async values => {
    const { dispatch, section } = this.props;

    await contact(values, dispatch);

    dispatch(updateSection({ section: section + 1 }));
  };

  submitShipping = async values => {
    const { dispatch, section } = this.props;

    const nextSection = () => {
      dispatch(updateSection({ section: section + 1 }));
    };

    const openAddressConfirmModal = suggestedAddress => {
      const handleAddress = address => {
        dispatch(
          changeReduxFormValue('purchase', 'purchase.shipping_address', address)
        );
        nextSection();
      };

      dispatch(
        openModal('ConfirmAddress', {
          maxWidth: 650,
          static: true,
          props: {
            suggestedAddress,
            currentAddress: values.purchase.shipping_address,
            onSubmit: handleAddress
          }
        })
      );
    };

    const { purchase, valid_address, suggested_address } = await shipping(
      values,
      dispatch
    );

    if (!purchase) {
      return;
    }

    dispatch(changePurchase(purchase));

    if (purchase.voucherCode) {
      dispatch(
        changeReduxFormValue(
          'purchase',
          'purchase.voucher.code',
          purchase.voucherCode
        )
      );
    }

    if (values.shipping.method === 'location') {
      return nextSection();
    }

    if (!valid_address) {
      // If the current address is not found valid, let the user confirm it
      return openAddressConfirmModal();
    }

    if (suggested_address) {
      // Add user name to the suggested address
      suggested_address.first_name =
        values.purchase.shipping_address.first_name;
      suggested_address.last_name = values.purchase.shipping_address.last_name;

      // Compare suggestion with current address to see if it has any differences
      const addressFieldsToCheck = [
        'address_1',
        'address_2',
        'city',
        'state',
        'postal_code',
        'country'
      ];

      const isSuggestionDifferent = addressFieldsToCheck.some(
        key => suggested_address[key] != values.purchase.shipping_address[key]
      );

      // If so, let the user confirm the suggested address
      if (isSuggestionDifferent) {
        return openAddressConfirmModal(suggested_address);
      }
    }

    nextSection();
  };

  submitPayment = async values => {
    const { dispatch, section, hasValidVoucherCode } = this.props;

    const card = await this.processPayment();

    if (card) {
      values['payment'] = card;
    }

    if (!hasValidVoucherCode && values.purchase.voucher) {
      values.purchase.voucher.code = null;
    }

    await payment(values, dispatch);
  };

  sectionHeader(sectNumber, sectName) {
    const { dispatch, section } = this.props;
    const editable = sectNumber < section;
    const editingClassName =
      sectNumber === section ? 'stores-PurchaseForm-header--editing' : '';

    return (
      <div className={`stores-PurchaseForm-header ${editingClassName}`}>
        <div>
          <h4>
            {sectNumber + 1}. {sectName}
          </h4>
        </div>
        <div>
          {editable && (
            <a
              onClick={e => {
                e.preventDefault();
                dispatch(updateSection({ section: sectNumber }));
              }}
              href="#"
            >
              edit
            </a>
          )}
        </div>
      </div>
    );
  }

  contactSection() {
    const { section } = this.props;
    const editing = contactSection === section;

    return (
      <React.Fragment>
        {this.sectionHeader(contactSection, 'Contact Info')}
        {section >= contactSection && (
          <Contact editing={editing} onSubmit={this.submitContact} />
        )}
      </React.Fragment>
    );
  }

  shippingSection() {
    const { section } = this.props;
    const editing = shippingSection === section;

    return (
      <React.Fragment>
        {this.sectionHeader(shippingSection, 'Shipping')}
        {section >= shippingSection && (
          <Shipping editing={editing} onSubmit={this.submitShipping} />
        )}
      </React.Fragment>
    );
  }

  paymentSection() {
    const { section } = this.props;
    const editing = paymentSection === section;

    return (
      <React.Fragment>
        {this.sectionHeader(paymentSection, 'Payment')}
        <Payment editing={editing} onSubmit={this.submitPayment} />
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="stores-PurchaseForm">
        <div>{this.contactSection()}</div>

        <div>{this.shippingSection()}</div>

        <div>{this.paymentSection()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  braintree: state.payment.braintree,
  paymentCapturable: getPaymentCapturable(state),
  section: state.purchaseForm.section,
  hasValidVoucherCode: state.purchase.hasValidVoucherCode
});

export default connect(mapStateToProps)(PurchaseForm);
