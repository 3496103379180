import { gaDimensions } from 'constants/AnalyticsConstants';

let Metrics;
let metrics;

Metrics = require('@customink/metrics').default;

Metrics.prototype.setDimension = function(dimensionName, dimensionValue) {
  const ga = window.ga;

  if (ga !== undefined && typeof ga === 'function') {
    let gaDimensionNumber = gaDimensions[dimensionName];

    if (gaDimensionNumber) {
      gaDimensionNumber = `dimension${gaDimensionNumber}`;
      ga('set', gaDimensionNumber, dimensionValue);
    }
  }
};

metrics = new Metrics();

export { metrics };
