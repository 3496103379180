import React from 'react';

import Carousel from './Carousel';

const BundleImages = ({ images, navigationClass }) => {
  return (
    <div className="stores-ProductDetails-images">
      <Carousel images={images} withNavigationClass={navigationClass} />
    </div>
  );
};

export default BundleImages;
