import axios from 'axios';

import { addCartItem } from 'actions/cart';
import { openModal } from 'actions/modal';
import Routes from 'lib/routes';
import { metrics } from 'utils/metrics';
import storeId from 'utils/storeId';

export default async function(dispatcher, values) {
  const request = {
    quantity: values.quantity,
    id: values.id,
    size: values.sizeName,
    variantId: values.variantId,
    variantName: values.variantName,
    amount: Number(values.amount)
  };

  try {
    const response = await axios.post(
      Routes.store_cart_path(storeId()),
      request
    );

    metrics.pageViewTag(`/os/${storeId()}/additem`);

    const { cart, lastAddedCartItem } = response.data;

    dispatcher(addCartItem(cart));
    dispatcher(openModal('ProductAdded'));
  } catch (error) {
    // TODO: Handle error messaging
    metrics.pageViewTag(`/os/${storeId()}/additem/error`);
    console.error(error.toString());
  }
}
