import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Alert } from 'components/elements';
import CartItem from 'components/CartItem';
import CartSummary from 'components/CartSummary';

import { CartItemsPropTypes } from 'utils/ReusablePropTypes';
import storeId from 'utils/storeId';
import Routes from 'lib/routes';
import {isInventory} from '../selectors/storePresentationConfig'

const CartPage = ({ errorMessage, items, quantity, isInventoryStore }) => {
  return (
    <div>
      <div className="cart-Header">
        <strong>YOUR CART</strong> ({quantity})
        <span className="cart-Header--continue-shopping">
          <a href={Routes.store_products_path(storeId())}>
            Continue shopping
          </a>
        </span>
      </div>

      <div className="cart-Container">
        <div className="cart-Items--container">
          {errorMessage && <Alert type="warning" text={errorMessage} />}
          {Object.keys(items).map(item => (
            <CartItem key={item} item={items[item]} readOnly={false} isInventoryStore={isInventoryStore}/>
          ))}
        </div>
        <div className="cart-Summary">
          <CartSummary />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  errorMessage: state.cart.error && state.cart.error.message,
  items: state.cart.items,
  quantity: state.cart.quantity,
  isInventoryStore: isInventory(state)
});

CartPage.propTypes = {
  errorMessage: PropTypes.string,
  items: CartItemsPropTypes,
  quantity: PropTypes.number,
  isInventoryStore: PropTypes.bool
};

export default connect(mapStateToProps)(CartPage);
