import React from 'react';
import PropTypes from 'prop-types';

import { InlineShareButtons } from 'sharethis-reactjs';

const ProductDetailsShareButtons = ({ productId }) => (
  <InlineShareButtons
    config={{
      alignment: 'left', // alignment of buttons (left, center, right)
      color: 'social', // set the color of buttons (social, white)
      // ONLY ENABLED FOR HEALTH + WELLNESS STORE FACEMASKS
      enabled: productId === 1819 || productId === 1842, // show/hide buttons (true, false)
      font_size: 16, // font size for the buttons
      labels: null, // button labels (cta, counts, null)
      language: 'en', // which language to use (see LANGUAGES)
      networks: [
        // which networks to include (see SHARING NETWORKS)
        'facebook',
        'twitter',
        'linkedin'
      ],
      padding: 12, // padding within buttons (INTEGER)
      radius: 6, // the corner radius on each button (INTEGER)
      show_total: false,
      size: 36 // the size of each button (INTEGER)
    }}
  />
);

ProductDetailsShareButtons.propTypes = {
  productId: PropTypes.number.isRequired
};

export default ProductDetailsShareButtons;
