import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required, numericality } from 'redux-form-validators';
import { connect } from 'react-redux';

import {
  ProductPropTypes,
  VariantPropTypes,
  VariantSizesPropTypes
} from 'utils/ReusablePropTypes';

import ProductSizing from 'components/ProductSizing';
import SizeSelect from 'components/SizeSelect';
import ColorSelect from 'components/ColorSelect';

import TimeLeft from 'components/TimeLeft';
import { Stepper } from 'components/FormGroups';
import { Button } from 'components/elements';

import {
  getCurrentVariant,
  variantsSelector,
  getInitialSizeValue,
  getCurrentSizeLabel
} from 'selectors/product';
import addCartItem from 'services/addCartItem';

const ProductForm = ({
  available,
  dispatch,
  currentSizeLabel,
  currentVariant,
  handleSubmit,
  initialValues,
  limited,
  minQuantity,
  product,
  sizes,
  submitting,
  timeLeftToOrder,
  valid,
  validSize,
  variants
}) => {
  const onSubmit = values => {
    return addCartItem(dispatch, {
      ...values,
      variantName: currentVariant.name
    });
  };

  return (
    <form className="sb-Form" onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="variantId"
        component={ColorSelect}
        label="Color"
        validate={required()}
        className="sb-Form-group--mr"
        currentVariant={currentVariant}
        variants={variants}
      />

      <Field
        name="sizeName"
        component={SizeSelect}
        label="Size"
        validate={required()}
        className="sb-Form-group--mr"
        currentSizeLabel={currentSizeLabel}
        currentVariant={currentVariant}
        sizes={sizes}
      />

      <div className="stores-ProductDetails-sizeChart">
        <ProductSizing product={product} />
      </div>

      <h2>
        Quantity
        {minQuantity > 1 && ` (Minimum ${minQuantity})`}
        {/* HACK: Delete special case for specific product */}
        {initialValues.id == 1819 ? ' (Packs)' : ''}
      </h2>
      <div className="stores-ProductDetails-actions">
        <Field
          name="quantity"
          component={Stepper}
          readOnly={false}
          validate={numericality({ '>=': minQuantity })}
          min={minQuantity}
          className="sb-Form-group--mr"
        />
        <div className="stores-ProductDetails-addToCart">
          <Button
            disabled={submitting || !valid || !validSize || !available}
            htmlType="submit"
          >
            {(submitting && 'Adding to cart…') ||
              (!available && 'Unavailable') ||
              'Add to Cart'}
          </Button>
          <TimeLeft limited={limited} timeLeftToOrder={timeLeftToOrder} />
        </div>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  const product = state.product;
  const currentVariant = getCurrentVariant(state);
  const variants = variantsSelector(state);
  const currentSizeLabel = getCurrentSizeLabel(state);
  const validSize = currentVariant.sizes.some(
    size => size.label === currentSizeLabel
  );

  return {
    initialValues: {
      quantity: state.product.minQuantity,
      sizeName: getInitialSizeValue(state),
      variantId: currentVariant.id,
      id: state.product.id
    },
    available: product.active,
    currentSizeLabel: currentSizeLabel,
    currentVariant: currentVariant,
    limited: product.limited,
    minQuantity: state.product.minQuantity,
    product: product,
    sizes: state.product.sizes,
    timeLeftToOrder: product.timeLeftToOrder,
    variants: variants,
    validSize
  };
}

ProductForm.propTypes = {
  available: PropTypes.bool.isRequired,
  currentSizeLabel: PropTypes.string,
  currentVariant: PropTypes.shape(VariantPropTypes).isRequired,
  limited: PropTypes.bool,
  minQuantity: PropTypes.number,
  product: PropTypes.shape(ProductPropTypes),
  sizes: VariantSizesPropTypes.isRequired,
  timeLeftToOrder: PropTypes.string,
  validSize: PropTypes.bool,
  variants: PropTypes.arrayOf(PropTypes.shape(VariantPropTypes)).isRequired
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'product'
  })(ProductForm)
);
