import React from 'react';
import classnames from 'classnames';
import { useTable } from 'react-table';
import { Spinner } from 'components/elements';

const Table = ({
  data,
  classNames,
  columns,
  emptyState,
  hover,
  loading,
  striped
}) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  const classes = classnames(
    classNames,
    hover && 'hover',
    striped && 'striped'
  );

  const rowClasses = row => {
    return classnames('admin-Table-row', row.id, row.original.className);
  };

  return (
    <div className="admin-Table">
      <table className={classes} {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  className={`admin-Table-header ${
                    column.id
                  } ${column.className || ''}`}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map(
            (row, i) =>
              prepareRow(row) || (
                <tr className={rowClasses(row)} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        className={`admin-Table-cell ${cell.column.className ||
                          ''}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              )
          )}
        </tbody>
      </table>
      {loading && (
        <div className="admin-Table-loading">
          <Spinner />
        </div>
      )}
      {rows.length <= 0 && emptyState}
    </div>
  );
};

export default props => <Table {...props} />;
