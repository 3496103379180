import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { numericality } from 'redux-form-validators';
import { connect } from 'react-redux';
import addCartItem from 'services/addCartItem';
import AmountSelect from 'components/AmountSelect';
import CustomAmount from 'components/CustomAmount';
import { Button } from 'components/elements';

const DonationProductForm = ({
  dispatch,
  change,
  handleSubmit,
  submitting,
  untouch,
  valid
}) => {
  const [otherAmountVisible, setOtherAmountVisible] = useState(false);

  const onSubmit = values => {
    return addCartItem(dispatch, values);
  };

  const handleSelectorChange = (event, value) => {
    if (value === 0) {
      setOtherAmountVisible(true);
      change('amount', undefined);
      untouch('amount');
    } else {
      setOtherAmountVisible(false);
      change('amount', value);
    }
  };

  const handleAmountChange = (event, value) => {
    value = value.replace(/\D/g, ``);
    let intValue = parseInt(value);
    value === '' || intValue === 0
      ? change('amount', undefined)
      : change('amount', intValue);
    event.preventDefault();
  };

  const amounts = [
    {
      value: 5,
      display: '$5'
    },
    {
      value: 10,
      display: '$10'
    },
    {
      value: 20,
      display: '$20'
    },
    {
      value: 50,
      display: '$50'
    },
    {
      value: 100,
      display: '$100'
    },
    {
      value: 0,
      display: 'Other Amount'
    }
  ];

  return (
    <form className="sb-Form" onSubmit={handleSubmit(onSubmit)}>
      <div className="stores-DonationProductDetails-amount">
        <Field
          name="selectedAmount"
          component={AmountSelect}
          readOnly={false}
          className="sb-Form-group--mr"
          amounts={amounts}
          onChange={handleSelectorChange}
        />
        <div hidden={!otherAmountVisible}>
          <Field
            name="amount"
            className="amountField"
            component={CustomAmount}
            readOnly={false}
            validate={numericality({
              '>=': 5,
              message: 'Donation amount must be $5 or more.'
            })}
            type="text"
            inputMode="numeric"
            autoComplete="off"
            maxLength="5"
            onChange={handleAmountChange}
            placeholder="USD "
          />
        </div>
      </div>
      <div className="stores-DonationProductDetails-addToCart">
        <Button disabled={submitting || !valid} htmlType="submit">
          {(submitting && 'Adding to cart…') || 'Add to Cart'}
        </Button>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {
    initialValues: {
      id: state.product.id,
      amount: undefined,
      quantity: state.product.minQuantity
    }
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'donationProduct'
  })(DonationProductForm)
);
