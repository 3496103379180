import React from 'react';

const CustomAmount = ({ input, meta, ...props }) => {
  return (
    <div>
      <div
        className={`stores-DonationProductDetails-otherAmount${
          input.value ? ' populated' : ''
        }${meta.touched && meta.invalid ? ' error' : ''}`}
      >
        <span className="stores-DonationProductDetails-otherAmount-currencySymbol">
          $
        </span>
        <input {...input} {...props} />
        <span className="stores-DonationProductDetails-otherAmount-centsPlaceholder">
          .00
        </span>
      </div>
      {(!meta.touched || meta.valid) && (
        <span className="sb-Form-help stores-DonationProductDetails-help">
          Please enter a donation amount of $5 or more.
        </span>
      )}
      {meta.touched && meta.invalid && (
        <span className="sb-Form-fieldErrors stores-DonationProductDetails-error">
          {meta.error}
        </span>
      )}
    </div>
  );
};

export default CustomAmount;
