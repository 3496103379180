import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { required, email } from 'redux-form-validators';
import { FormGroup } from 'components/Purchase/FormGroup';
import { connect } from 'react-redux';
import updatePurchase from '../../services/updatePurchase';
import { closeModal } from '../../actions/modal';
import { purchaseSummaryMessage } from '../../actions/purchaseSummary';
import { updatePurchaseConfirmation } from 'actions/purchaseConfirmation';
import { metrics } from 'utils/metrics';

const PurchaseUpdateEmail = ({
  handleSubmit,
  close,
  submitting,
  error,
  purchaseSecureId,
  handleEmailChange
}) => {
  function cancelUpdate() {
    metrics.pageViewTag('/os/orderstatus/statuspage/update/changeemail/close');
    close();
  }
  return (
    <>
      <div className="stores-Modal-header">
        <h1>Change Your Email Address</h1>
      </div>

      <form
        className="sb-Form"
        onSubmit={handleSubmit(handleEmailChange.bind(this, purchaseSecureId))}
      >
        <div className="stores-Modal-content">
          <Field
            name="email"
            type="text"
            component={FormGroup}
            label="Email"
            validate={[required(), email()]}
            autoComplete="email"
          />
          {error && (
            <div className="sb-Alert sb-Alert--error">
              <p>{error}</p>
            </div>
          )}
        </div>

        <div className="stores-Modal-footer added-Product-modal-actions">
          <button
            type="submit"
            className="sb-Btn sb-Btn--primary sb-Btn--responsive"
            disabled={submitting}
          >
            Update Email
          </button>
          <button
            className="sb-Btn sb-Btn--secondary sb-Btn--responsive sb-Btn--mr"
            onClick={cancelUpdate}
            disabled={submitting}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

PurchaseUpdateEmail.propTypes = {
  handleEmailChange: PropTypes.func.isRequired
};

const PurchaseUpdateEmailForm = reduxForm({
  form: 'shippingEmail',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnBlur: true,
  touchOnChange: true
})(PurchaseUpdateEmail);

const mapStateToProps = state => ({
  initialValues: {
    email: state.purchaseConfirmation.email
  },
  purchaseSecureId: state.purchase.secureId
});

const mapDispatchToProps = dispatch => ({
  handleEmailChange: async (purchaseSecureId, values) => {
    const data = {
      id: purchaseSecureId,
      purchase: { email: values.email }
    };
    try {
      const { purchaseConfirmation, errors } = await updatePurchase(
        dispatch,
        data
      );

      if (errors) {
        throw new SubmissionError(errors.purchase);
      } else {
        dispatch(closeModal());
        dispatch(purchaseSummaryMessage('Your order has been updated'));
        dispatch(updatePurchaseConfirmation(purchaseConfirmation));
        metrics.pageViewTag('/os/orderstatus/statuspage/update/changeemail/success');
      }
    } catch (e) {
      metrics.pageViewTag('/os/orderstatus/statuspage/update/changeemail/error');
      if (e instanceof SubmissionError) {
        throw e;
      }
      throw new SubmissionError({
        _error: 'Sorry. Updating your email address has failed.'
      });
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseUpdateEmailForm);
