import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PurchaseConfirmationPropTypes } from 'utils/ReusablePropTypes';
import { InlineShareButtons } from 'sharethis-reactjs';

import Logo from 'components/Logo';
import AddressBlock from 'components/AddressBlock';
import { default as Vitals } from 'components/Purchase/PurchaseConfirmationVitals';

import storeId from 'utils/storeId';
import { translateAddressAttributesToSnakeCase } from 'utils/translateAddressAttributes';
import Routes from 'lib/routes';

const PurchaseConfirmationPageSummary = ({
  purchase,
  store,
  handleCancelOrder,
  summaryMessage
}) => {
  const {
    active,
    canceled,
    cancelable,
    cancelUrl,
    email,
    firstName,
    lastName,
    phoneNumber,
    placedOn,
    referenceId,
    refundAmount,
    refundTimeframe,
    status,
    total
  } = purchase;

  return (
    <div className="sb-Wrapper">
      <div className="stores-PurchaseConfirmation-summary">
        <div className="stores-PurchaseConfirmation-summaryHeader">
          <div>
            <a href={Routes.store_products_path(storeId())}>
              <Logo section="PurchaseConfirmation" {...store} />
            </a>
            <h1>{summaryMessage}</h1>
            {!canceled && <h2>Your order has been placed.</h2>}
            {canceled && (
              <React.Fragment>
                <h2>Your order has been canceled</h2>
                <p>
                  Your refund will be credited to your original payment method
                  within {refundTimeframe}.
                </p>
              </React.Fragment>
            )}
            <div className="stores-PurchaseConfirmation-summaryContact">
              <div className="stores-PurchaseConfirmation-summaryContactInformation">
                <p>
                  <strong>Contact Information</strong>
                  <br />
                  {firstName} {lastName}
                  <br />
                  {email}
                  <br />
                  {phoneNumber}
                </p>
              </div>
              <div>
                {purchase.shippable && (
                  <div>
                    <strong>Shipping Address</strong>

                    <AddressBlock
                      address={translateAddressAttributesToSnakeCase(
                        purchase.shippingAddress
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            {store.bundles.length > 0 && (
              <div className="stores-PurchaseConfirmation-summaryBuyMore">
                <strong>Interested in buying more?</strong>&nbsp;
                <a href={Routes.store_products_path(storeId())}>
                  Return to kit product page
                </a>
              </div>
            )}
            <InlineShareButtons
              config={{
                alignment: 'left', // alignment of buttons (left, center, right)
                color: 'social', // set the color of buttons (social, white)
                // ONLY ENABLED FOR HEALTH + WELLNESS STORE
                enabled: store.id === 132, // show/hide buttons (true, false)
                font_size: 16, // font size for the buttons
                labels: 'cta', // button labels (cta, counts, null)
                language: 'en', // which language to use (see LANGUAGES)
                networks: [
                  // which networks to include (see SHARING NETWORKS)
                  'facebook',
                  'twitter',
                  'linkedin'
                ],
                padding: 12, // padding within buttons (INTEGER)
                radius: 6, // the corner radius on each button (INTEGER)
                show_total: false,
                size: 40, // the size of each button (INTEGER)

                // OPTIONAL PARAMETERS
                url:
                  'https://www.customink.com/products/categories/face-masks/205/styles' // (defaults to current url)
              }}
            />
          </div>
          <Vitals
            placedOn={placedOn}
            referenceId={referenceId}
            cancelable={cancelable}
            cancelUrl={cancelUrl}
          />
        </div>
      </div>
    </div>
  );
};

PurchaseConfirmationPageSummary.propTypes = {
  purchase: PropTypes.shape(PurchaseConfirmationPropTypes)
};

const mapStateToProps = ({
  purchaseConfirmation,
  store,
  purchaseSummaryMessage,
  featureFlags
}) => ({
  purchase: purchaseConfirmation,
  store: store,
  summaryMessage: purchaseSummaryMessage,
  featureFlags: featureFlags
});

export default connect(mapStateToProps)(PurchaseConfirmationPageSummary);
