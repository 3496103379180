import React, { useState } from 'react';

import PropTypes from 'prop-types';
import SizeChart from './SizeChart';
import SizeLineup from './SizeLineup';

export const ProductSizing = ({ product: { sizeChart, sizeLineup } }) => {
  const [sizeChartHidden, updateSizeChartHidden] = useState(true);

  const handleOpenSizingLineup = e => {
    e.preventDefault();
    const name = 'sizingInfo';
    const options =
      'width=640,height=700,scrollbars=yes,toolbar=yes,menubar=no,location=yes,resizable=yes';
    window.open(sizeLineup, name, options);
  };

  const handleToggleSizeChart = e => {
    updateSizeChartHidden(!sizeChartHidden);
  };

  const sizeChartOrLineup = () => {
    if (sizeLineup != undefined) {
      return 'lineup';
    } else if (sizeChart != undefined) {
      return 'chart';
    }
  };

  const chartOrLineup = sizeChartOrLineup();

  return (
    <div>
      {chartOrLineup == 'lineup' && (
        <SizeLineup
          handleOpenSizingLineup={handleOpenSizingLineup}
          sizeLineupURL={sizeLineup}
        />
      )}
      {chartOrLineup == 'chart' && (
        <SizeChart
          sizeChartHTML={sizeChart}
          isHidden={sizeChartHidden}
          handleToggleSizeChart={handleToggleSizeChart}
        />
      )}
    </div>
  );
};

ProductSizing.propTypes = {
  product: PropTypes.shape({
    sizeChart: PropTypes.string,
    sizeLineup: PropTypes.string
  })
};

export default ProductSizing;
