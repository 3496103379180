import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { FormGroup } from 'components/Purchase/FormGroup';
import { Button } from 'components/elements';

const Voucher = props => {
  const {
    code,
    handleRemove,
    handleSubmit,
    onSubmit,
    orderSummaryVoucherLabel,
    pristine,
    removable,
    hasValidCode
  } = props;

  const voucherFormClassName = `sb-Form--inline--flex stores-OrderSummary-voucher${(hasValidCode &&
    '--valid') ||
    ''}`;

  const voucherLabel = orderSummaryVoucherLabel || 'Voucher Code';

  return (
    <form
      className="sb-Form"
      style={{
        marginBottom: '1em'
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="stores-OrderSummary-lineItem">
        {voucherLabel}:
        {hasValidCode && (
          <>
            <span className="stores-OrderSummary-lineItemValue">
              {removable && (
                <a href="#" onClick={handleRemove}>
                  Remove
                </a>
              )}
            </span>
            <br />
            <small className="stores-OrderSummary-voucherApplied">
              <em>{code}</em>
            </small>
          </>
        )}
        <span className={voucherFormClassName}>
          <Field
            name="purchase.voucher.code"
            type="text"
            placeholder="Voucher Code"
            component={FormGroup}
            readOnly={hasValidCode}
            className="sb-Form-group--small sb-Form-group--mr"
          />
          <span className="sb-Form-group sb-Form-group--small stores-OrderSummary-voucherButton">
            <Button
              disabled={pristine || hasValidCode}
              className="sb-Btn--small"
              htmlType="submit"
              type="primary"
            >
              Apply
            </Button>
          </span>
        </span>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  const selector = formValueSelector('purchase');
  return {
    code: selector(state, 'purchase.voucher.code'),
    hasValidCode: state.purchase.hasValidVoucherCode,
    orderSummaryVoucherLabel: state.purchase.orderSummaryVoucherLabel,
    removable: state.purchase.voucherCodeRemovable
  };
};

Voucher.propTypes = {
  editing: PropTypes.bool,
  handleRemove: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'purchase',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnBlur: false
  })(Voucher)
);
