import React from 'react';
import { Provider } from 'react-redux';
import Container from 'components/RecommendedProductsContainer';

const ConnectedRecommendedProducts = props => (
  <Provider store={ReactOnRails.getStore('appStore')}>
    <Container />
  </Provider>
);

export default ConnectedRecommendedProducts;
