/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
// var componentRequireContext = require.context("components", true);
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import ReactOnRails from 'react-on-rails';
import axios from 'axios';

// all top level connected components that need access to
// redux state
import ConnectedBundleContainer from '../components/ConnectedBundleContainer';
import ConnectedCart from '../components/ConnectedCart';
import ConnectedCartPage from '../components/ConnectedCartPage';
import ConnectedLocationDashboard from '../components/ConnectedLocationDashboard';
import ConnectedModal from '../components/Modal/ConnectedModal';
import ConnectedProductDetails from '../components/ConnectedProductDetails';
import ConnectedProductsContainer from '../components/ConnectedProductsContainer';
import ConnectedProgressBar from '../components/ConnectedProgressBar';
import ConnectedPurchaseConfirmationPage from '../components/Purchase/ConnectedPurchaseConfirmationPage';
import ConnectedPurchasePage from '../components/Purchase/ConnectedPurchasePage';
import ConnectedRecommendedProducts from '../components/ConnectedRecommendedProducts';
import SiteAnnouncements from '../components/SiteAnnouncements';

import { metrics } from 'utils/metrics';
import appStore from '../store/store';

import { setupCSRFToken } from 'utils/csrf';
setupCSRFToken();

import '../helpers/StickyHeader';

window.metrics = metrics;

ReactOnRails.registerStore({
  appStore
});

ReactOnRails.register({
  ConnectedBundleContainer,
  ConnectedCart,
  ConnectedCartPage,
  ConnectedLocationDashboard,
  ConnectedModal,
  ConnectedProductDetails,
  ConnectedProductsContainer,
  ConnectedProgressBar,
  ConnectedPurchaseConfirmationPage,
  ConnectedPurchasePage,
  ConnectedRecommendedProducts,
  SiteAnnouncements
});

