import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { normalize } from 'utils/images';

export const variantsSelector = state => state.product.variants;
export const sizesSelector = state => state.product.sizes;
export const imageUrlSelector = state => state.product.imageUrl;
export const currentVariantSelector = state => state.currentVariant;

const variantIdSelector = state => {
  const selector = formValueSelector('product');
  return selector(state, 'variantId');
};

export const getCurrentSize = state => {
  const selector = formValueSelector('product');

  return selector(state, 'sizeName');
};

export const getCurrentVariantQuantity = state => {
  const selector = formValueSelector('product');
  return selector(state, 'quantity');
};

export const getCurrentVariant = createSelector(
  variantsSelector,
  variantIdSelector,
  currentVariantSelector,
  (variants, variantId, currentVariant) => {
    const variant = variants.find(variant => variant.id === variantId);

    return variant || currentVariant || variants[0];
  }
);

export const getProductImages = createSelector(
  getCurrentVariant,
  imageUrlSelector,
  (variant, productImageUrl) =>
    variant ? normalize(variant.images) : [{ source: productImageUrl }]
);

export const getCurrentSizeLabel = createSelector(
  sizesSelector,
  getCurrentSize,
  (sizes, size) => {
    const currentSize = sizes.find(vSize => vSize.value === size);
    return currentSize ? currentSize.label : '';
  }
);

export const getInitialSizeValue = createSelector(getCurrentVariant, variant =>
  variant.sizes.length == 1 ? variant.sizes[0].value : null
);
