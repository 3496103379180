import React from 'react';

const sizeOrder = [
  'YS',
  'YM',
  'YL',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  '3XL',
  '4XL',
  '5XL'
];

export function getGroupedItems(items) {
  const groupedItems = {};

  // Group items by variantId or storeProductId
  items.forEach(item => {
    const groupKey = item.variantId || item.storeProductId;
    if (!groupedItems[groupKey]) {
      groupedItems[groupKey] = [];
    }
    groupedItems[groupKey].push(item);
  });

  // Order items in the same group by size or amount in case of donations
  Object.entries(groupedItems).map(([key, itemGroup]) => {
    groupedItems[key] = itemGroup.sort((item1, item2) => {
      const indexSize1 = sizeOrder.indexOf(item1.attributes.Size);
      const indexSize2 = sizeOrder.indexOf(item2.attributes.Size);

      if (indexSize1 < 0 || indexSize2 < 0) {
        return (
          item1.attributes.Size ??
          item1.attributes.Amount ??
          ''
        ).localeCompare(item2.attributes.Size ?? item2.attributes.Amount);
      }
      return indexSize1 - indexSize2;
    });
  });

  // Reduce the items by summing those with same size
  Object.entries(groupedItems).map(([key, itemGroup]) => {
    groupedItems[key] = itemGroup.reduce((accumulator, currentItem) => {
      const existingItem = accumulator.find(
        item =>
          (item.attributes.Size ?? item.attributes.Amount) ===
          (currentItem.attributes.Size ?? currentItem.attributes.Amount)
      );
      if (existingItem) {
        existingItem.quantity += currentItem.quantity;
      } else {
        accumulator.push(currentItem);
      }
      return accumulator;
    }, []);
  });

  return groupedItems;
}

const ShipmentTable = ({ shipment, bundle, showStatus = true }) => {
  const {
    items,
    undecorated,
    internationalDeliveryInfo,
    requiresShipping
  } = shipment;

  const { statusDate, statusName, statusMessage } = shipment.status;

  const groupedItems = getGroupedItems(items);

  const pendingDelivery =
    statusName !== 'delivered' && statusName !== 'canceled';

  const deliveryEstimateMessage = () => {
    return bundle ? bundleDeliveryEstimate() : deliveryEstimate();
  };

  const deliveryEstimate = () => {
    if (undecorated) {
      if (statusName === 'shipped') {
        if (internationalDeliveryInfo) {
          return (
            <>
              Shipped on {statusDate}, {internationalDeliveryInfo}
            </>
          );
        } else {
          return (
            <>
              Shipped on {statusDate} <br />
              Estimated delivery within {shipment.deliveryEstimate}
            </>
          );
        }
      } else if (pendingDelivery) {
        if (internationalDeliveryInfo) {
          return (
            <>
              Estimated to ship on {shipment.fulfillmentDate} <br />
              {internationalDeliveryInfo}
            </>
          );
        } else {
          return (
            <>
              Estimated to ship on {shipment.fulfillmentDate}, delivery within{' '}
              {shipment.deliveryEstimate}
            </>
          );
        }
      }
    } else if (pendingDelivery) {
      return (
        <>
          Estimated Delivery:{' '}
          <span className="stores-Text--alert">
            {shipment.deliveryEstimate}
          </span>
          <br />
        </>
      );
    }
  };

  const bundleDeliveryEstimate = () => (
    <span className="store-Text--alert">
      Submitted on: {shipment.fulfillmentDate}
      <br />
      {bundle.deliveryMessageOverride}
    </span>
  );

  const shipmentStatus = (() => {
    return (
      <React.Fragment>
        {deliveryEstimateMessage()}
        {statusName == 'delivered' && shipment.location && (
          <React.Fragment>
            Haven't received your items? Please contact{' '}
            <a href={`mailto:${shipment.location.email}`}>
              {shipment.location.name}
            </a>
            .
            <br />
          </React.Fragment>
        )}
        {(statusName === 'shipped' || statusName === 'delivered') &&
          shipment.trackingUrl && (
            <div className="trackingInfo">
              <a
                href={shipment.trackingUrl}
                className="sb-Btn sb-Btn--secondaryBlack sb-Btn--small"
                target="_blank"
              >
                Track Shipment
              </a>
            </div>
          )}
      </React.Fragment>
    );
  })();

  return (
    <table className="stores-PurchaseConfirmationShipmentTable">
      <thead>
        <tr>
          <th align="left">Details</th>
          {showStatus && <th align="left">Status</th>}
        </tr>
      </thead>
      <tbody>
        {Object.entries(groupedItems).map(([_, itemGroup], index) => {
          const quantityLabel = itemGroup[0].attributes.Color
            ? `${itemGroup[0].attributes.Color} | Qty: `
            : 'Qty: ';

          return (
            <tr key={index}>
              <td className="productNameColumn">
                <img src={itemGroup[0].image} valign="middle" />
                <div className="productDetailsContainer">
                  <span className="productNameText">
                    <strong>{itemGroup[0].productName}</strong>
                  </span>
                  <div>
                    {quantityLabel}
                    {itemGroup.reduce(
                      (total, { quantity }) => total + quantity,
                      0
                    )}
                  </div>
                  <div className="productSizeChipsContainer">
                    {itemGroup.map(item => (
                      <div
                        key={`${item.variantId ?? item.storeProductId}-
                      ${item.attributes.Size}`}
                        className="productSizeChip"
                      >
                        {item.attributes.Size} {item.quantity}
                        {item.attributes.Amount &&
                          ` x Amount: ${item.attributes.Amount}`}
                      </div>
                    ))}
                  </div>
                </div>
              </td>
              {showStatus && index == 0 && (
                <td
                  className="statusColumn"
                  valign="top"
                  rowSpan={`${items.length}`}
                >
                  <h3>{statusMessage}</h3>
                  {requiresShipping && shipmentStatus}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ShipmentTable;
