const initialState = {
  id: null,
  locations: []
};

const store = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default store;
