import { createStore, combineReducers } from 'redux';

import { reducer as reduxFormReducer } from 'redux-form';

//reducers
import bundle from 'reducers/bundle';
import bundleProducts from 'reducers/bundleProducts';
import cart from 'reducers/cart';
import currentVariant from 'reducers/currentVariant';
import featureFlags from 'reducers/featureFlags';
import filters from 'reducers/filters';
import itemStatuses from 'reducers/itemStatuses';
import modal from 'reducers/modal';
import payment from 'reducers/payment';
import product from 'reducers/product';
import products from 'reducers/products';
import profit from 'reducers/profit';
import purchase from 'reducers/purchase';
import purchaseConfirmation from 'reducers/purchaseConfirmation';
import purchaseForm from 'reducers/purchaseForm';
import purchaseOptions from 'reducers/purchaseOptions';
import recommendedProducts from 'reducers/recommendedProducts';
import shippingCountries from 'reducers/shippingCountries';
import store from 'reducers/store';
import user from 'reducers/user';
import purchaseSummaryMessage from 'reducers/purchaseSummaryMessage';
import storePresentationConfig from 'reducers/storePresentationConfig';

import { devToolsEnhancer } from 'redux-devtools-extension';

const combinedReducers = combineReducers({
  bundle,
  bundleProducts,
  cart,
  currentVariant,
  featureFlags,
  filters,
  itemStatuses,
  modal,
  payment,
  product,
  products,
  profit,
  purchase,
  purchaseConfirmation,
  purchaseForm,
  purchaseOptions,
  recommendedProducts,
  shippingCountries,
  purchaseSummaryMessage,
  store,
  storePresentationConfig,
  user,
  form: reduxFormReducer
});

const configureStore = railsProps => {
  return createStore(combinedReducers, railsProps, devToolsEnhancer());
};

export default configureStore;
