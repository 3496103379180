import React from 'react';
import { connect } from 'react-redux';

import { ShippingAddressPropTypes } from 'utils/ReusablePropTypes';

const AddressBlock = ({ address, shippingCountries }) => {
  const { full_name, first_name, last_name, address_1, address_2, city, state, postal_code } = address

  const fullName = full_name || [first_name, last_name].join(' ').trim()
  const country = shippingCountries.find(({ code }) => code === address.country) || {};

  return (
    <div>
      {fullName && (<><strong>{fullName}</strong><br/></>)}
      {address_1}<br/>
      {address_2 && (<>{address_2}<br/></>)}
      {city}, {state} {postal_code}<br/>
      {country.name}
    </div>
  )
};

AddressBlock.propTypes = {
  address: ShippingAddressPropTypes
};

const mapStateToProps = state => ({
  shippingCountries: state.shippingCountries
})

export default connect(mapStateToProps)(AddressBlock);
