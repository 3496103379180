import React from 'react';
import capitalize from 'lodash/capitalize';

export const hasErrors = (field, errors) => {
  if (errors[field] && errors[field].length > 0) {
    return true;
  }
};

export const renderError = (field, errors) => {
  if (hasErrors(field, errors)) {
    const error = capitalize(
      field.split('_').join(' ') + ' ' + errors[field][0]
    );
    return <div className="sb-Form-fieldErrors">{error}</div>;
  }
};
