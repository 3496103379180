import React from 'react';

import { SizeBox } from 'components/elements';
import { CustomSelect } from 'components/FormGroups';

const SizeSelect = ({
  currentSizeLabel,
  currentVariant,
  sizes,
  input,
  meta
}) => {
  const sizeLabels = currentVariant.sizes.map(size => size.label);

  let options = sizes.map((size, index) => ({
    id: size.value,
    value: size.value,
    disabled: !sizeLabels.includes(size.label),
    data: {
      size: size.label
    }
  }));

  options = options.length > 1 ? options : [];

  const label = (
    <span>
      Size: <span style={{ fontWeight: '100' }}>{currentSizeLabel}</span>
    </span>
  );
  return (
    <CustomSelect
      $component={SizeBox}
      input={input}
      label={label}
      meta={meta}
      options={options}
    />
  );
};

export default SizeSelect;
