import React from 'react';
import PropTypes from 'prop-types';
import {
  LocationPropTypes,
  PurchaseOptionsPropTypes,
  ShippingCountriesPropTypes,
  ShippingAddressPropTypes
} from 'utils/ReusablePropTypes';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { required } from 'redux-form-validators';

import { Alert, Button } from '../elements';
import Summary from 'components/Purchase/Summary';
import AddressBlock from 'components/AddressBlock';
import { FormGroup } from './FormGroup';
import { changePurchase } from 'actions/purchase';
import { nbsp } from 'constants/StringConstants';
import { translateAddressAttributesToSnakeCase } from 'utils/translateAddressAttributes';

const getItemByCode = (list, code) => {
  return list.find(item => item.code === code);
};

export const ShippingDisplay = ({
  locationId,
  locations,
  address,
  shippingMethod
}) => {
  if (shippingMethod === 'location') {
    const location = locations.find(({ id }) => id === locationId);

    address = {
      ...translateAddressAttributesToSnakeCase(location),
      full_name: `To ${location.locationName}`
    };
  }

  return <AddressBlock address={address} />;
};

export const Shipping = ({
  dispatch,
  editing,
  error,
  handleSubmit,
  locationId,
  locations,
  purchaseOptions,
  shippingAddressAttributes,
  shippingCountries,
  shippingMethod,
  shippingWarning,
  submitting
}) => {
  const changePurchaseOption = (locationId, dispatch, purchaseOptions) => {
    const newPurchase = purchaseOptions[locationId];
    dispatch(changePurchase(newPurchase));
  };
  const clearSelectedState = () => {
    dispatch(change('purchase', 'purchase.shipping_address.state', undefined));
  };

  if (!editing) {
    return (
      <ShippingDisplay
        locationId={locationId}
        locations={locations}
        address={shippingAddressAttributes}
        shippingMethod={shippingMethod}
      />
    );
  }

  const selectedCountry =
    getItemByCode(shippingCountries, shippingAddressAttributes.country) || {};
  const countryStates = selectedCountry.states || [];

  const renderLocationDropDown = (
    <div className="w-1/2--lg">
      <br />
      <Field
        name="shipping.locationId"
        component="select"
        onChange={e =>
          changePurchaseOption(e.target.value, dispatch, purchaseOptions)
        }
      >
        {locations.map((location, index) => (
          <option key={index} value={location.id}>
            {location.locationName} - {location.address1}
          </option>
        ))}
      </Field>
      <br />
    </div>
  );

  return (
    <div>
      <div className="sb-Util-visible--sm">
        <Summary />
      </div>
      <form className="sb-Form" onSubmit={handleSubmit}>
        {error && <Alert type="error" text={error} />}
        {locations.length > 0 && purchaseOptions.hasOwnProperty('individual') && (
          <div className="sb-Form-group">
            <label className="sb-Form-radio">
              <Field
                name="shipping.method"
                component="input"
                type="radio"
                value="location"
                checked={shippingMethod === 'location'}
                onChange={() =>
                  changePurchaseOption(locationId, dispatch, purchaseOptions)
                }
              />
              <span className="sb-Form-indicator" />
              Ship to your business location - FREE
            </label>
            {shippingMethod === 'location' && renderLocationDropDown}
            <br />
            <label className="sb-Form-radio">
              <Field
                name="shipping.method"
                component="input"
                type="radio"
                value="individual"
                checked={shippingMethod === 'individual'}
                onChange={e =>
                  changePurchaseOption(
                    e.target.value,
                    dispatch,
                    purchaseOptions
                  )
                }
              />
              <span className="sb-Form-indicator" />
              Ship to your address -{' '}
              {purchaseOptions.individual.shipping == '$0.00'
                ? 'FREE'
                : purchaseOptions.individual
                    .shipping /* TODO: Once stores that ship to location will allow international, this may become inaccurate */}
            </label>
          </div>
        )}
        {shippingMethod === 'individual' && (
          <div>
            <div className="sb-Form-group sb-Form--inline--flex">
              <Field
                name="purchase.shipping_address.first_name"
                type="text"
                component={FormGroup}
                label="Ship To"
                placeholder="First Name"
                className="sb-Form-group--mr"
                autoComplete="given-name"
              />
              <Field
                name="purchase.shipping_address.last_name"
                type="text"
                component={FormGroup}
                label={nbsp}
                placeholder="Last Name"
                autoComplete="family-name"
              />
            </div>

            <Field
              name="purchase.shipping_address.address_1"
              type="text"
              component={FormGroup}
              label="Address Line 1"
              validate={required()}
              autoComplete="address-line1"
            />
            <Field
              name="purchase.shipping_address.address_2"
              type="text"
              component={FormGroup}
              label="Address Line 2 (Optional)"
              autoComplete="address-line2"
            />

            <div className="sb-Form-group sb-Form--inline--flex">
              <div className="w-2/3--lg">
                <Field
                  name="purchase.shipping_address.city"
                  type="text"
                  component={FormGroup}
                  label="City"
                  validate={required()}
                  className="sb-Form-group--mr"
                  autoComplete="address-level2"
                />
              </div>

              <div className="w-1/3--lg">
                <Field
                  name="purchase.shipping_address.postal_code"
                  type="text"
                  component={FormGroup}
                  label="Zip Code"
                  placeholder="Zip Code"
                  validate={required()}
                  autoComplete="postal-code"
                />
              </div>
            </div>

            <div className="sb-Form-group sb-Form--inline--flex">
              <div className="w-2/3--lg">
                <Field
                  name="purchase.shipping_address.country"
                  type="select"
                  component={FormGroup}
                  label="Country"
                  placeholder="Select country"
                  validate={required()}
                  options={shippingCountries}
                  optionValue="code"
                  optionLabel="name"
                  className="sb-Form-group--mr"
                  autoComplete="country"
                  onChange={() => clearSelectedState()}
                />
              </div>

              <div className="w-1/3--lg">
                <Field
                  name="purchase.shipping_address.state"
                  type={countryStates.length ? 'select' : 'text'}
                  component={FormGroup}
                  label="State"
                  placeholder={
                    countryStates.length ? 'Select state' : 'Enter state'
                  }
                  options={countryStates}
                  optionValue="code"
                  optionLabel="name"
                  validate={countryStates.length ? required() : null}
                  autoComplete="address-level1"
                />
              </div>
            </div>
            {shippingWarning && (
              <div className="sb-Form-group">
                <span className="sb-Form-help">{shippingWarning}</span>
              </div>
            )}
          </div>
        )}
        {locations.length > 0 && !purchaseOptions.hasOwnProperty('individual') && (
          <div className="sb-Form-group">
            <label className="sb-Form">Choose your business location</label>
            {shippingMethod === 'location' && renderLocationDropDown}
          </div>
        )}
        <div className="stores-PurchaseForm-actions sb-Form-group sb-Form-group--buttons">
          {editing && (
            <Button
              htmlType="submit"
              className="primaryBlack"
              disabled={submitting}
            >
              {submitting ? 'Validating…' : 'Next'}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => {
  const selector = formValueSelector('purchase');

  const { shippingWarning, locations } = state.store;

  const defaultCountry = 'US';
  const defaultLocationId = locations.length > 0 ? locations[0].id : null;
  const defaultShippingMethod =
    locations.length > 0 ? 'location' : 'individual';
  const firstName = selector(state, 'purchase.first_name');
  const lastName = selector(state, 'purchase.last_name');

  return {
    initialValues: {
      purchase: {
        first_name: firstName,
        last_name: lastName,
        email: selector(state, 'purchase.email'),
        phone_number: selector(state, 'purchase.phone_number'),
        accepts_marketing: selector(state, 'purchase.accepts_marketing'),
        shipping_address: {
          first_name: firstName,
          last_name: lastName,
          country: defaultCountry
        }
      },
      shipping: {
        method: defaultShippingMethod,
        locationId: defaultLocationId
      }
    },
    locationId: parseInt(selector(state, 'shipping.locationId'), 10),
    locations: state.store.locations,
    purchaseOptions: state.purchaseOptions,
    shippingCountries: state.shippingCountries,
    shippingAddressAttributes:
      selector(state, 'purchase.shipping_address') || {},
    shippingMethod: selector(state, 'shipping.method') || defaultShippingMethod,
    shippingWarning
  };
};

Shipping.propTypes = {
  editing: PropTypes.bool,
  locationId: PropTypes.number,
  locations: PropTypes.arrayOf(PropTypes.shape(LocationPropTypes)).isRequired,
  onSubmit: PropTypes.func.isRequired,
  purchaseOptions: PurchaseOptionsPropTypes,
  shippingAddressAttributes: ShippingAddressPropTypes,
  shippingCountries: ShippingCountriesPropTypes,
  shippingMethod: PropTypes.oneOf(['location', 'individual'])
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'purchase',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnBlur: false
  })(Shipping)
);
