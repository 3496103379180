import { createSelector } from 'reselect';

import storeId from 'utils/storeId';
import Routes from 'lib/routes';

const recommendedProductsSelector = state => state.recommendedProducts;
const cartProductIdsSelector = state =>
  Object.keys(state.cart.items).map(key => state.cart.items[key].id);

export const getRecommendedProducts = createSelector(
  recommendedProductsSelector,
  cartProductIdsSelector,
  (recommendedProducts, cartProductIds) =>
    recommendedProducts.filter(product => !cartProductIds.includes(product.id))
);

export const getNormalizedRecommendedProducts = createSelector(
  getRecommendedProducts,
  recommendedProducts =>
    recommendedProducts.map(product => {
      return {
        href: Routes.store_product_path(storeId(), product.id),
        imageUrl: product.imageUrl,
        key: product.id,
        name: product.name
      };
    })
);
