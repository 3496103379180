import React from 'react';
import { connect } from 'react-redux';

import ProductDetails from 'components/ProductDetails';
import DonationProductDetails from 'components/DonationProductDetails';
import PropTypes from "prop-types";

const ProductDetailsContainer = ({ detailsProvider }) => {
  return (
    <div className="stores-ProductDetailsContainer">
      <div className="stores-ProductDetails">
        {detailsProvider === 'mms' && <ProductDetails />}
        {detailsProvider === 'donation' && <DonationProductDetails />}
      </div>
    </div>
  );
};

ProductDetailsContainer.propTypes = {
  detailsProvider: PropTypes.string
};

function mapStateToProps({ product }) {
  return {
    detailsProvider: product.detailsProvider
  };
}

export default connect(mapStateToProps)(ProductDetailsContainer);
