import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import PropTypes from 'prop-types';

import { VariantPropTypes } from 'utils/ReusablePropTypes';
import { Button } from 'components/elements';
import TimeLeft from 'components/TimeLeft';
import ColorSelect from 'components/ColorSelect';
import SizeSelect from 'components/SizeSelect';
import ProductSizing from 'components/ProductSizing';

import {
  getCurrentSizeLabel,
  getCurrentVariant
} from '../selectors/bundleProducts';
import buyNow from 'services/buyNow';
import {
  ProductsPropTypes,
  BundleProductsPropTypes
} from '../utils/ReusablePropTypes';

const BundleForm = ({
  bundleProducts,
  currentValues,
  dispatch,
  handleSubmit,
  products,
  submitting,
  valid
}) => {
  const onSubmit = values => {
    const items = values.products.map((product, index) => {
      const variantName = products[product.index].variants.find(
        variant => variant.id == product.variantId
      ).name;

      return {
        id: product.id,
        quantity: product.quantity,
        size: product.sizeName,
        variantId: product.variantId,
        variantName: variantName
      };
    });

    return buyNow(dispatch, items);
  };

  const renderProductFields = (product, productIndex) => {
    const currentProductValues = currentValues.products[productIndex];
    const currentVariant = currentProductValues.currentVariant;
    const currentSizeLabel = currentProductValues.currentSizeLabel;

    return (
      <div
        className="stores-BundleDetails-productFieldsGroup"
        key={productIndex}
      >
        <h2>Choose your {product.name}:</h2>
        <Field
          name={`products[${productIndex}].variantId`}
          component={ColorSelect}
          label="Color"
          validate={required()}
          className="sb-Form-group--mr"
          currentVariant={currentVariant}
          variants={product.variants}
        />
        <Field
          name={`products[${productIndex}].sizeName`}
          component={SizeSelect}
          label="Size"
          validate={required()}
          className="sb-Form-group--mr"
          currentSizeLabel={currentSizeLabel}
          currentVariant={currentVariant}
          sizes={product.sizes}
        />
        <ProductSizing product={product} />
      </div>
    );
  };

  const currentSizesValid = currentValues.products.every(
    product => product.validSize
  );

  return (
    <form className="sb-Form" onSubmit={handleSubmit(onSubmit)}>
      <>
        {products.map(
          (product, productIndex) =>
            (product.variants.length > 1 || product.sizes.length > 1) &&
            renderProductFields(product, productIndex)
        )}
        <div className="stores-ProductDetails-actions">
          <div className="stores-BundleDetails-addToCart">
            <Button
              htmlType="submit"
              disabled={submitting || !valid || !currentSizesValid}
            >
              {(submitting && 'Submitting…') || 'Buy This Kit'}
            </Button>
            <TimeLeft
              limited={bundleProducts.limited}
              timeLeftToOrder={bundleProducts.timeLeftToOrder}
            />
          </div>
        </div>
      </>
    </form>
  );
};

const mapStateToProps = state => {
  const products = state.bundleProducts.products;

  const initialProductValues = products.map((product, productIndex) => {
    const initialVariant = product.variants[0];
    const initialSizeName =
      initialVariant.sizes.length == 1 ? initialVariant.sizes[0].value : null;

    return {
      id: product.id,
      index: productIndex,
      quantity: product.minQuantity,
      sizeName: initialSizeName,
      variantId: initialVariant.id
    };
  });

  const currentProductValues = products.map((product, productIndex) => {
    const selectorProps = { product: product, productIndex: productIndex };
    const currentVariant = getCurrentVariant(state, selectorProps);
    const currentSizeLabel = getCurrentSizeLabel(state, selectorProps);
    return {
      currentVariant: currentVariant,
      currentSizeLabel: currentSizeLabel,
      validSize: currentVariant.sizes.some(
        size => size.label === currentSizeLabel
      )
    };
  });

  return {
    initialValues: {
      products: initialProductValues
    },
    currentValues: {
      products: currentProductValues
    },
    bundleProducts: state.bundleProducts,
    products: products
  };
};

BundleForm.propTypes = {
  initialValues: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        sizeName: PropTypes.string,
        variantId: PropTypes.number.isRequired
      })
    )
  }).isRequired,
  currentValues: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        currentVariant: PropTypes.shape(VariantPropTypes).isRequired,
        currentSizeLabel: PropTypes.string.isRequired,
        validSize: PropTypes.bool.isRequired
      })
    )
  }).isRequired,
  bundleProducts: PropTypes.shape(BundleProductsPropTypes),
  products: ProductsPropTypes
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'bundleProducts'
  })(BundleForm)
);
